import React, { useEffect, useRef, useState } from "react";
//import Button from '@material-ui/core/Button';
import "./submitted.css";
import { Link, Redirect } from "react-router-dom";
import { IP } from "../../../ip/IPConfig";
import { Button } from "@material-ui/core";
import moment from "moment";
import Pagination from "../../../Pagination";
import * as _ from "lodash";
import { Spinner1 } from "../../../Spinners";
import waiting2 from "../../../images/waiting2.gif";

function Submitted_Quotes() {
  // const classes = useStyles();

  // state used to search the quote
  const [search, setSearch] = useState("");

  // car quotes array
  const [carquotes, setcarquotes] = useState(null);

  // check if the dealer is approved or not
  const [approve, setApprove] = useState(false);

  // set the no of quotes to show per page
  const [showPerPage, setShowPerPage] = useState(8);
  
  // pagination object to show only 8 per page
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  // on page change set next 8 quotes on next page 
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const [sortedArray, setSortedarray] = useState([]);

  // check the customer approval each time page loads
  useEffect(() => {
    if (sessionStorage.getItem("approved") === "pending") {
      setApprove(true);
    }
  },[]);

  // fetch car quotes each time page loads
  useEffect(async () => {
    await fetchCarQuotes();
    sessionStorage.setItem("prevNext", 0);
  }, []);

  // takes search input to search quotes
  const handleChange = (e) => {
    console.log("change", e.target.value);
    if (e.target.value === "") {
      fetchCarQuotes();
    }
    setSearch(e.target.value);
  };

  // fetches the car quotes
  async function fetchCarQuotes() {
    console.log(
      `authorization Bearer ${sessionStorage.getItem(
        "idToken"
      )} url: ${IP}/dealer/bid/list/1`
    );

    // fetch the car quotes from the server of specific dealer by dealer id
    await fetch(
      `${IP}/dealer/bid/list/${sessionStorage.getItem("dealer_id")}`,
      {
        headers: new Headers({
          // authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        console.log("quotes data ", response);
        sessionStorage.setItem("carquotes", response);
        setcarquotes(response);
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  }

  // search quotes from the server
  function handleSearchClick(e) {
    e.preventDefault();
    console.log("search ", search);
    fetch(
      `${IP}/dealer/search/quotes/${sessionStorage.getItem(
        "dealer_id"
      )}/${search}`,
      {
        method: "GET",
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setcarquotes(response);
        console.log("response : ", response);
      })
      .catch((err) => {
        console.log("error in fetching search  ", err);
      });
  }

  // array sorting function to sort array by date and car maker
  const sortArray = (type) => {
    const types = {
      Date: "timestamp",
      Brand: "car_maker",
    };
    const sortProperty = types[type];
    let sorted = _.sortBy(carquotes, sortProperty); // lodash sortBy function is used to sort array
    if (types[type] == "timestamp") {
      sorted = sorted.reverse();
    }
    setcarquotes(sorted); // after sorting set the sorted array in car quotes so that it is displayed dynamically
  };

  if (approve) {
    return (
      <div>
        <Redirect to="/dealer/waiting" />
      </div>
    );
  }

  return (
    <div>
      <div className="search-box-2">
        <div>
          <input
            placeholder="Search quotes by quote I'd , Email , Phone"
            className="inbox-search"
            type="search"
            id="search"
            name="search"
            onChange={handleChange}
          ></input>
          <Button
            className="search-btn"
            value={search}
            onClick={handleSearchClick}
          >
            Search
          </Button>
        </div>
        <div className="sorting-div">
          <h5>Sort By : </h5>
          <select
            onChange={(e) => sortArray(e.target.value)}
            className="sort-select"
          >
            <option value="select">Select value</option>
            <option value="Date">Date</option>
            <option value="Brand">Brand</option>
          </select>
        </div>
      </div>
      {/* <div className={classes.searchContainer}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
        className={classes.searchInput}
          // onChange={handleSearchChange}
          label="Search quotes by quote I'd, Email, Phone"
          variant="standard"
          />
        </div> */}
      {carquotes != null ? (
        carquotes.length > 0 ? (
          <div>
            <div className="submitted-quotes-card-main-container">
              {carquotes.slice(pagination.start, pagination.end).map((data) => {
                return (
                  <div className="submitted-quotes-card-container">
                    <div className="submitted-quote-card-stack">
                      <div className="submitted-quote-card-stack-text">
                        <div>
                          <small>{data.car_maker}</small>

                          <h5>{data.car_model}</h5>
                        </div>

                        <div className="quote-card-stack-img">
                          {/* <img src={newcar} alt="New Car" /> */}
                          {/* <h2
                            ref={target}
                            onClick={() => handleBidDelete(data.bids_id)}
                            className="fa fa-times"
                            type="button"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete Quote"
                          ></h2> */}
                          {data.car_mileage == 0 ? <p>New Car</p> : null}
                        </div>
                      </div>
                    </div>
                    <div className="submitted-quote-card-stack-down">
                      <div style={{ textAlign: "right", margin: "3% 0%" }}>
                        {/* <img src="https://img.icons8.com/color/30/000000/time-machine--v1.png" />{" "} */}
                        <small style={{ marginTop: "3%" }}>
                          {moment(data.timestamp).fromNow()}
                        </small>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          <p style={{ display: "inline" }}>Quote Id : </p>
                          <p style={{ display: "inline" }}> {data.quote_id}</p>
                        </div>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/color/30/000000/cruise-control-on.png" />
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          Mileage
                        </div>
                        <div style={{ marginTop: "3%", marginLeft: "5%" }}>
                          <h6>{data.car_mileage}</h6>
                        </div>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/color/30/000000/google-maps.png" />
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          State
                        </div>
                        <div
                          style={{
                            marginTop: "3%",
                            marginLeft: "5%",
                          }}
                        >
                          <h6>{data.car_state}</h6>
                        </div>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/color/30/000000/place-marker--v2.png" />{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          Zip code
                        </div>
                        <div style={{ marginTop: "3%", marginLeft: "5%" }}>
                          <h6>{data.car_zipcode}</h6>
                        </div>
                      </div>

                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/ios/30/000000/expensive-2--v2.png" />{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          Price
                        </div>
                        <div style={{ marginTop: "3%", marginLeft: "5%" }}>
                          <h6>{"$"+data.amount}</h6>
                        </div>
                      </div>

                      <div className="buttons-div">
                        <Link to={`/dealer/quote/${data["quote_id"]}`}>
                          <button>Quote</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Pagination
              showPerPage={showPerPage}
              onPaginationChange={onPaginationChange}
              total={carquotes.length}
            />
          </div>
        ) : (
          <div className="no-bids-yet-wrapper">
            <div className="no-bid-inner-div">
              <div className="no-bid-text-area">
                <h2>Submitted Quotes will appear here</h2>
                <h6>Currently you have not submitted any quotes</h6>
              </div>
              <div className="no-bid-img-area">
                <img src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fwaiting2.gif?alt=media&token=1d99d8ea-9848-41d8-8732-48e6a1673e88" className="no-bid-img" />
              </div>
            </div>
          </div>
        )
      ) : (
        <Spinner1 />
      )}
    </div>
  );
}
export default Submitted_Quotes;
