import {
  Button,
  TextField,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import React, { Component } from "react";
import { Redirect } from "react-router";
import hero1 from "../../images/hero.webp";
import { IP } from "../../ip/IPConfig";
import { firebasecode } from "../../../firebase/firebase";
import firebase from "firebase";
import "./CustomerLogin.css";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import { auth } from "../../../firebase/firebase";

import { connect } from "react-redux";
import { customerLogin, customerLoginData } from "../../../redux/actions";
import swal from "sweetalert";

const mapStateToProps = (state) => {
  return {
    loginState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginData: (data) => {
      dispatch(customerLogin(data));
    },
    afterLoginData: (data) => {
      dispatch(customerLoginData(data));
    },
  };
};

export class CustomerLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // login creds storing states
      username: "", // username stores the email
      password: "",

      // login flag
      login: false,

      //loader flag
      loader: false,

      // wrong password flag
      WrongPass: false,

      // forgot password click flag
      customerForgot: false,

      // response of forgot password api
      forgotPassResCustomer: "",

      //show password checkbox state
      showPassword: "",

      // id token from google
      idToken: "",
    };
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0 }); // scrolls to top of the screen every time refresh
  };

  // called when we click the forgot password button an email is sent from the backend to the user with a reset password link
  handleForgotPassword = () => {
    let user = this.state.username;

    console.log(this.state.username);

    auth
      .sendPasswordResetEmail(user)
      .then((res) => {
        console.log("res", res);
        alert("Reset link sent to your email id");
      })
      .catch((error) => {
        this.setState({ forgotPassResDealer: "User not found" });
        alert("User not found");
      });
  };

  // function used to store the input values in respective states
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handle login functio takes 2 arguments username and password and is called inside handleSubmit function
  async handleLogin(userName, password = "password") {

    // POST the user password and email to the backend/server
    fetch(`${IP}/customer/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${
          this.state.idToken == ""
            ? sessionStorage.getItem("idToken")
            : this.state.idToken
        }`,
      },
      body: JSON.stringify({
        user_email: userName,
        user_password: "password",
      }),
    })
      // after response we store all the required values in the session storage for further use
      .then(async (res) => {
        const response = await res.json();
        sessionStorage.setItem("role", response["role"]);
        sessionStorage.setItem(
          "user_name",
          response.result[0]["user_firstname"]
        );
        // sessionStorage.setItem("token", response["token"]);
        sessionStorage.setItem("customerId", response.result[0]["customer_id"]);
        sessionStorage.setItem("user_email", response.result[0]["user_email"]);
        sessionStorage.setItem("user_phone", response.result[0]["user_phn"]);
        this.props.onLoginData(response);

        sessionStorage.setItem(
          "loginResponse",
          JSON.stringify(this.props.loginState.formSubmitReducer.loginData)
        );

        sessionStorage.setItem("carquotes", JSON.stringify(response));
        this.setState({
          login: true,
          loader: false,
        });
        // reload required to render the customer page
        window.location.reload();
      })
      .catch((err) => {
        console.log("error in login ", err);
        this.setState({ WrongPass: true, loader: false });
      });
  }

  // ths function is called when we click the login button
  handleSubmit = (e) => {
    this.setState({
      loader: true, // displays the loading icon on login button until user is logged in
    });
    e.preventDefault();
    console.log(this.state.username, this.state.password);
    try {
      auth
        .signInWithEmailAndPassword(this.state.username, this.state.password)
        .then(() => {
          auth.currentUser
            .getIdToken(true)
            .then((idToken) => {
              // console.log("idToken",idToken)
              sessionStorage.setItem("idToken", idToken);
              setTimeout(() => {
                this.handleLogin(this.state.username, this.state.password); // calling the login function
              }, 1000);
            })
            .catch((error) => {
              alert("not gettting token");
            });
        })
        .catch((err) => {
          swal("Error", err.message, "error");
          this.setState({ loader: false });
        });
    } catch (err) {
      alert("please check the network");
      this.setState({ loader: false });
    }
  };

  // google login function
  handleGoogleLogin = (e) => {
    e.preventDefault();
    const provider = new firebase.auth.GoogleAuthProvider(); // getting the google auth provider
    firebasecode
      .auth()
      .signInWithPopup(provider) // google signup with popup api
      .then((result) => {
        /* @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;

        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then((idToken1) => {
            // Send token to your backend via HTTPS
            // ...
            sessionStorage.setItem("idToken", idToken1);
            this.setState({
              idToken: idToken1,
            });
            this.handleLogin(result.user.email);
          });

        // The signed-in user info.
        var user = result.user;
      
      })
      .catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });
  };

  // invalid credentials snackbar close function
  handleClose = () => {
    this.setState({
      WrongPass: false,
    });
  };

  // snackbar popup slide direction
  TransitionUp(props) {
    return <Slide {...props} direction="left" />;
  }
  render() {
    // if login is true redirects to the customer main page
    if (this.state.login) {
      return (
        <div>
          <Redirect to="/customer" />
        </div>
      );
    } else {
      return (
        <div>
          <div className="container-login">
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              autoHideDuration={6000}
              open={this.state.WrongPass}
              onClose={this.handleClose}
              TransitionComponent={this.TransitionUp}
              message="Wrong Password"
              key="up"
            >
              <Alert
                onClose={this.handleClose}
                variant="filled"
                severity="error"
              >
                {/* Invalid Credentials */}
                Please enter a registered email id
              </Alert>
            </Snackbar>
            <div className="customer-login-hero-section">
              <div className="login-hero-text">
                <h1>Welcome Back!</h1>
                <h5>Let's get you the best deal</h5>
              </div>
              <div className="login-hero-img-section">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fhero.webp?alt=media&token=597b5fe9-4522-44e0-9b4d-c22deeb9509f"
                  className="login-hero-img"
                />
              </div>
            </div>
          </div>
          <form className="dealer-login-card" onSubmit={this.handleSubmit}>
            <h2 style={{ textAlign: "center" }}>Customer Login</h2>
            {this.state.customerForgot == false ? ( // if false then show email and password or else show only email for password recovery
              <div>
                <TextField
                  required
                  style={{ marginBottom: "5%" }}
                  variant="outlined"
                  label="Email Address"
                  onChange={this.handleChange}
                  fullWidth
                  name="username"
                  type="email"
                />
                <br />
                <TextField
                  required
                  style={{ marginBottom: "5%" }}
                  variant="outlined"
                  label="Password"
                  type={this.state.showPassword ? "" : "password"}
                  autoComplete="off"
                  onChange={this.handleChange}
                  fullWidth
                  name="password"
                />
                <div className="checkbox-div">
                  <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                    checked={this.state.showPassword}
                  />
                  <label
                    htmlFor="checkpass"
                    onClick={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  >
                    Show Password
                  </label>
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    width: "100%",
                    background: "#01ACED",
                    color: "white",
                  }}
                >
                  {" "}
                  {this.state.loader ? (
                    <>
                      Loading
                      <CircularProgress />
                    </>
                  ) : (
                    <>Login</>
                  )}{" "}
                </Button>
                <p
                  className="forgot-password"
                  onClick={() =>
                    this.setState({
                      customerForgot: !this.state.customerForgot,
                    })
                  }
                >
                  Forgot Password?
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                  }}
                >
                  OR
                </p>

                <button
                  className="google-card"
                  onClick={this.handleGoogleLogin}
                >
                  <img src="https://img.icons8.com/fluent/48/000000/google-logo.png" />
                  <strong>Login with Google</strong>
                </button>
                <a href="/customer/signup" className="new-signup">
                  New User? Signup !
                </a>
              </div>
            ) : (
              <div>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "red",
                    textAlign: "right",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      customerForgot: false,
                      forgotPassResCustomer: "",
                    })
                  }
                >
                  &#128473;
                </p>
                {this.state.forgotPassResCustomer == "" ? null : this.state
                    .forgotPassResCustomer == "User not found" ? (
                  <div class="alert alert-danger" role="alert">
                    User not found !
                  </div>
                ) : (
                  <div class="alert alert-success" role="alert">
                    Recovery Email Sent to{" "}
                    {this.state.forgotPassResCustomer.email} !
                  </div>
                )}
                <TextField
                  required
                  style={{ marginBottom: "5%" }}
                  variant="outlined"
                  label="Email Id"
                  onChange={this.handleChange}
                  fullWidth
                  name="username"
                  type="email"
                />
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    width: "100%",
                    background: "#01ACED",
                    color: "white",
                  }}
                  onClick={this.handleForgotPassword}
                >
                  {" "}
                  Send a password Reset E-mail{" "}
                </Button>
              </div>
            )}
          </form>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLogin);
