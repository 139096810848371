//items to be showed in navbar for customer navbar when loginRole is customer

export const CustomerMenuList = [
    {
      title: "Quotes",
      url: "/customer",
    },
    {
      title: "Bids",
      url: "/customer/quote/bid/1",
    },
    {
        title:"Add Vehicle",
        url:'/customer/quote'
    },
    {
      title: "Profile",
      url: "/customer/profile",
    },
    {
      title:'Logout',
      url:'/logout'
    }
  ];
  