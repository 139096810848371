import React, { Component } from "react";
import "./ErrorPage.css";
import error from "../images/2696450.webp";
export class ErrorPage extends Component {
  render() {
    return (
      <div>
        <div className="error-main-div">
          <h2
            style={{
              textAlign: "center",
              color: "rebeccapurple",
              fontSize: "355%",
            }}
          >
            Page Not Found
          </h2>
          <img
            className="error-img"
            src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2F2696450.webp?alt=media&token=b175144f-13d3-4e6b-af7b-7645ecd5ef93"
          />
        </div>
      </div>
    );
  }
}

export default ErrorPage;
