import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { auth } from '../../../firebase/firebase'

export class Logout extends Component {
    
    // used to clear all the session storage when user logs out of the app
    componentDidMount(){
        sessionStorage.clear();
        sessionStorage.removeItem('idToken');
        auth.signOut();
        window.location.reload();
    }
    render() {
        // after that redirect to home page
        return (
            <div>
                <Redirect to="/" />
            </div>
        )
    }
}

export default Logout
