import {
  Button,
  TextField,
  CircularProgress,
  Checkbox,
  Grid,
} from "@material-ui/core";
import React, { Component } from "react";
import { Redirect } from "react-router";
import { IP } from "../../../ip/IPConfig";
import { firebasecode } from "../../../../firebase/firebase";
import firebase from "firebase";
import hero1 from "../../../images/hero.webp";
import "./dealerlogin.css";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import { auth } from "../../../../firebase/firebase";

export class DealerLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // login creds are stored here in this states
      emailId: "",
      password: "",

      // store idToken
      idToken1: "",

      // login success flag
      login: false,

      // loader flag loader is the rotating circle displayed on the log in button
      loader: false,

      // wrong password flag
      WrongPass: false,

      // valid registered mail flag
      ValidEmail: false,

      // forgot password flag
      dealerForgot: false,

      // forgot password response state
      forgotPassResDealer: "",

      // show password checkbox state
      showPassword: false,
    };
  }

  componentDidMount = () => {
    // scroll to top whenever the component loads
    window.scrollTo({ top: 0 });
  };

  // forgot password handler function
  handleForgotPassword = () => {
    let user = this.state.emailId;

    console.log(this.state.emailId);

    auth
      .sendPasswordResetEmail(user)
      .then((res) => {
        console.log("res", res);
        alert("Reset link sent to your email id");
      })
      .catch((error) => {
        this.setState({ forgotPassResDealer: "User not found" });
      });
  };

  // input values are stored in the respective states
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // google login function to get the credentials from goole and log the user in
  handleGoogleLogin = (e) => {
    e.preventDefault();
    let token1 = "";
    console.log("google login");
    const provider = new firebase.auth.GoogleAuthProvider(); // firebase google auth provider
    firebasecode
      .auth()
      .signInWithPopup(provider) // pop up sign in option provided by google
      .then((result) => {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            // Send token to your backend via HTTPS
            // ...
            token1 = idToken;
            console.log(idToken);
            sessionStorage.setItem("idToken", idToken);
          })
          .catch((error) => {
            // Handle error
            console.log(error);
          });
        /* @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        console.log("cred", credential);
        // sessionStorage.setItem("idToken", credential.accessToken);

        // This gives you a Google Access Token. You can use it to access the Google API.
        // The signed-in user info.
        var user = result.user;
        console.log("user", user);
        console.log(
          user.displayName,
          user.email,
          user.photoURL,
          user.phoneNumber
        );
        var emailId = user["email"];

        console.log("emailId", emailId);
        console.log("user.id", user.uid);

        // call to handle login and passing the mail id and password to it for login
        setTimeout(() => {
          this.handleLogin(emailId, token1);
        }, 1000);
      })
      .catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
        console.log(errorMessage);
      });
  };

  // handleLogin function takes emailId and password as arguments and logs in to dealer quotes
  handleLogin(emailId, token = null) {
    console.log(token);
    // try catch for exception handling4
    fetch(`${IP}/dealer/login`, {
      // fetching the data from the server
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${
          token ? token : sessionStorage.getItem("idToken")
        }`,
        // authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        user_email: emailId,
        // user_password: password,
      }),
    })
      .then(async (res) => {
        // after successful login response and the required data is stored in the session for use in other components
        const response = await res.json();
        // const response = await res.text();

        // response is stored in session storage
        console.log("Login Success ", response);
        console.log("Login Success ", response["result"][0]["status"]);
        sessionStorage.setItem("dealerLoginResponse", JSON.stringify(response));
        sessionStorage.setItem("role", response["role"]);
        sessionStorage.setItem("user_id", response["result"][0]["user_id"]);
        sessionStorage.setItem("dealer_id", response["result"][0]["dealer_id"]);
        sessionStorage.setItem(
          "dealer_name",
          response["result"][0]["user_firstname"]
        );
        sessionStorage.setItem("approved", response["result"][0]["status"]);

        // loader and login flags status changed for redirecting to the dealer dashboard
        this.setState({
          login: true,
          loader: false,
        });
        sessionStorage.setItem("Login", true);

        // reload required to render the dealer page
        window.location.reload();
      })
      .catch((err) => {
        // catching the error in login if any
        this.setState({ WrongPass: true, loader: false });
        console.log("error in login ", err);
        console.log("error in login ", err["message"]);
      });
  }

  // calling the firebase sign in method fro authentication
  firebaseSignIn = (emailId, password) => {
    try {
      auth
        .signInWithEmailAndPassword(emailId, password)
        .then(() => {
          auth.currentUser
            .getIdToken(true)
            .then((idToken) => {
              sessionStorage.setItem("idToken", idToken);
              setTimeout(this.handleLogin(emailId), 1800);
            })
            .catch((error) => {
              // Handle error
              alert("not gettng token");
              this.setState({ loader: false });
            });
        })
        .catch((err) => {
          if (err.code == "auth/user-not-found") {
            this.setState({ ValidEmail: true, loader: false });
          }
          if (err.code == "auth/wrong-password") {
            this.setState({ WrongPass: true, loader: false });
          }
          console.log("error ", err.code);
        });
    } catch (err) {
      this.setState({ WrongPass: true, loader: false });
    }
  };

  // submit function called after we submit the login form
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loader: true,
    });
    console.log(this.state.emailId, this.state.password);
    // this.setState({ loader: false });

    this.firebaseSignIn(this.state.emailId, this.state.password);

    // alert("Please check the user credentials")
  };
  // invalid credentials snackbar cole function
  handleClose = () => {
    this.setState({
      WrongPass: false,
      ValidEmail: false,
    });
  };

  // snackbar entry transition
  TransitionUp(props) {
    return <Slide {...props} direction="left" />;
  }
  render() {
    // if login flag is true redirects to the dealer dashboard
    if (this.state.login) {
      return (
        <div>
          <Redirect to="/dealer" />
        </div>
      );
    }
    return (
      <div>
        <div class="container-login">
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            open={this.state.WrongPass}
            onClose={this.handleClose}
            TransitionComponent={this.TransitionUp}
            message="Wrong Password"
            key="up"
          >
            <Alert onClose={this.handleClose} variant="filled" severity="error">
              Incorrect Password
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
            open={this.state.ValidEmail}
            onClose={this.handleClose}
            TransitionComponent={this.TransitionUp}
            message="Wrong Password"
            key="up"
          >
            <Alert onClose={this.handleClose} variant="filled" severity="error">
              Enter registered Email id
            </Alert>
          </Snackbar>

          <div className="dealer-login-hero-section">
            <div className="login-hero-text">
              <h1>Welcome back!</h1>
              <h5>Let's help our customers</h5>
            </div>
            <div className="login-hero-img-section">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fhero.webp?alt=media&token=597b5fe9-4522-44e0-9b4d-c22deeb9509f"
                className="login-hero-img"
              />
            </div>
          </div>
        </div>
        <form className="dealer-login-card" onSubmit={this.handleSubmit}>
          <h2>Dealer Login</h2>
          {this.state.dealerForgot == false ? ( // if forgot password is not clicked sho normal login form
            <div>
              <TextField
                required
                variant="outlined"
                label="Email Address"
                onChange={this.handleChange}
                fullWidth
                name="emailId"
                type="email"
              />
              <TextField
                required
                style={{ marginTop: "5%", marginBottom: "5%" }}
                variant="outlined"
                label="Password"
                onChange={this.handleChange}
                fullWidth
                name="password"
                type={this.state.showPassword ? "" : "password"}
              />
              <div className="checkbox-div">
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                  checked={this.state.showPassword}
                />
                <label
                  htmlFor="checkpass"
                  onClick={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                >
                  Show Password
                </label>
              </div>
            </div>
          ) : (
            // if forgot password flag is true show this form
            <div className="dealer-forgot-password">
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "red",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.setState({
                    dealerForgot: false,
                    forgotPassResDealer: "",
                  })
                }
              >
                &#128473;
              </p>
              {this.state.forgotPassResDealer == "" ? null : this.state
                  .forgotPassResDealer == "User not found" ? (
                <div class="alert alert-danger" role="alert">
                  Please enter a registered email id
                </div>
              ) : (
                <div class="alert alert-success" role="alert">
                  Recovery Email Sent to {this.state.forgotPassResDealer.email}{" "}
                  !
                </div>
              )}
              <TextField
                required
                variant="outlined"
                label="Email Id"
                onChange={this.handleChange}
                fullWidth
                name="emailId"
              />
              <Grid item xs={11} sm={11} className="btn-div">
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    width: "100%",
                    background: "#01ACED",
                    color: "white",
                  }}
                  onClick={this.handleForgotPassword}
                >
                  {" "}
                  Send a password Reset E-mail{" "}
                </Button>
              </Grid>
            </div>
          )}

          {this.state.dealerForgot == false ? ( // conditional rendering for forgot password
            <Button
              variant="contained"
              type="submit"
              style={{
                width: "100%",
                width: "100%",
                background: "#01ACED",
                color: "white",
              }}
            >
              {" "}
              {this.state.loader ? (
                <>
                  Loading
                  <CircularProgress />
                </>
              ) : (
                <>Login</>
              )}{" "}
            </Button>
          ) : null}
          {this.state.dealerForgot == false ? ( // conditional rendering for forgot password
            <p
              className="forgot-password"
              onClick={() =>
                this.setState({ dealerForgot: !this.state.dealerForgot })
              }
            >
              Forgot Password?
            </p>
          ) : null}
          {this.state.dealerForgot == false ? (
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "larger",
                }}
              >
                OR
              </p>
              <button className="google-card" onClick={this.handleGoogleLogin}>
                <img
                  src="https://img.icons8.com/fluent/48/000000/google-logo.png"
                  alt="img"
                />
                <strong>Login with Google</strong>
              </button>

              <a href="/dealer/signup" className="new-signup">
                New User? Signup !
              </a>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

export default DealerLogin;
