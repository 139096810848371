import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import React, { Component } from "react";
import { IP, token } from "../../ip/IPConfig";
import "./AddVehicle.css";
import Select from "react-select";
import swal from "sweetalert";

import {isZipcode} from "../../ValidationFunctions"

export class AddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // all the vehicle information states
      carMaker: "",
      carModel: "",
      mileage: "",
      year: "",
      state: "",
      zipcode: "",
      comment: "",
      status: false,

      // car maker and model list states
      carMakerList: [],
      carModelList: [],

      // selected car & its brand is stored in this states
      carMakerSelected: "",
      carModelSelected: "",

      // success state
      addSuccessful: false,

      // year array stores the years fro 2010 uptill now
      yearArray: [],

      // new car checkbox state
      newCarCheck: false,

      // car added flag
      successfullyAdded: false,

      //validated flags
      carMakeCheck: false,
      carModelCheck: false,
      carYearCheck: false,
      stateCheck: false,
      carMileageCheck: false,
      zipcodeCheck: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    // storing array of years from 2010 to now with the help of loop
    const date = new Date(); // date object
    let end = date.getFullYear() - 2010; // get todays year and minus 2010 we get no. of years from 2010 till now
    for (let index = 0; index <= end+1; index++) {
      // from 0 to end we have for loop which stores value in the year array

      this.state.yearArray[index] = {
        value: (2010 + index).toString(),
        label: (2010 + index).toString(),
      };
    }
    this.state.yearArray = this.state.yearArray.reverse()
  }

  fieldsCheck = () => {
    if (this.state.carMakerSelected == "") {
      this.setState({ carMakeCheck: true });
      document.getElementById("add-vehicle").scrollIntoView();
    } else if (this.state.carModel == "") {
      this.setState({ carModelCheck: true });
      document.getElementById("add-vehicle").scrollIntoView();
    } else if (this.state.year == "") {
      this.setState({ carYearCheck: true });
      document.getElementById("add-vehicle").scrollIntoView();
    } else if (this.state.mileage == "") {
      this.state.carMileageCheck = true
    } else if (this.state.state == "") {
      this.setState({ stateCheck: true });
      document.getElementById("add-vehicle").scrollIntoView();
    } else if (this.state.zipcode == "") {
      this.setState({ zipcodeCheck: true });
  
     
    }
  };

  // stores the input values to corresponding states
  handleChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.value < 0 ? e.target.value * -1 : e.target.value,
    });

    if (this.state.mileage !== "") {
      this.setState({ carMileageCheck: false });
    }
    if (this.state.zipcode !== "") {
      this.setState({ zipcodeCheck: false });
    } 
  };

  // this is the function which fetches the car models based on the car brand selected
  handleChangeDropDown = (e) => {
    try {
      this.setState({
        carMakerSelected: e,
      });
    } finally {
      this.fetchCarModel(e);
    }
  };

  // car model fetch function and store it in the carModelList
  fetchCarModel(carModel) {
    this.setState({ carModelList: [], carModel: "" });
    fetch(`${IP}/car/get/carmodel/${carModel}`)
      .then(async (res) => {
        var response = await res.json();
        response.map((data, index) => {
          this.state.carModelList[index] = {
            value: data.car_model,
            label: data.car_model,
          };
        });
      })
      .catch((err) => {
        console.log("error in fetching car models", err);
      });
  }
  async componentDidMount() {
    window.scrollTo({ top: 0 });

    // get all the car mekers list from the server
    await fetch(`${IP}/car/get/carmaker`, {
      method: "get",
    })
      .then(async (res) => {
        var response = await res.json();
        response.map((data, index) => {
          this.state.carMakerList[index] = {
            // data stored in form of object and label
            value: data.car_maker,
            label: data.car_maker,
          };
        });
        console.log("car makers : ", this.state.carMakerList);
      })
      .catch((err) => {
        console.log("error in customer add vehicle ", err);
      });
  }

  // asynchronous submit function used to submit all the vehicle data to the server
  async handleSubmit(e) {
    e.preventDefault();
    console.log(
      this.state.carMakerSelected,
      this.state.carModel,
      this.state.mileage,
      this.state.year,
      this.state.zipcode,
      this.state.comment
    );

    // making a post request to the server
    if (
      this.state.carMakerSelected !== "" &&
      this.state.carModel !== "" &&
      this.state.year !== "" &&
      this.state.state !== ""
    ) {
      fetch(
        `${IP}/customer/add/Vehicle/${sessionStorage.getItem("customerId")}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
          },
          // all the values are passed in the body
          body: JSON.stringify({
            carMaker: this.state.carMakerSelected,
            carModel: this.state.carModel,
            year: this.state.year,
            mileage: this.state.mileage,
            carNew: this.state.status,
            state: this.state.state,
            zipcode: this.state.zipcode,
            comments: this.state.comment,
            timestamp: "", //provided by server
          }),
        }
      )
        .then(async (res) => {
          const response = await res.json();
          console.log("added successfully", response);
          // all the states used to store data are cleared after success
          this.setState({
            addSuccessful: true,
            carMaker: "",
            carModel: "",
            year: "",
            mileage: "",
            carNew: "",
            state: "",
            zipcode: "",
            comments: "",
            timestamp: "",
          });
          swal("Success", "Vehicle added Successfully", "success");
          this.setState({ successfullyAdded: true }); // state flag for success
        })
        .catch((err) => {
          console.log("error in adding car", err);
          swal("Error", "Try again in some time", "error");
        });
    } else {
      console.log("Enter valid values");
    }
  }
  render() {
    if (this.state.carMakerSelected !== "") {
      this.state.carMakeCheck = false;
    }
    if (this.state.carModel !== "") {
      this.state.carModelCheck = false;
    }
    if (this.state.year !== "") {
      this.state.carYearCheck = false;
    }
    if (this.state.mileage == 0) {
      this.state.carMileageCheck = false;
    }
    if (this.state.state !== "") {
      this.state.stateCheck = false;
    }

    // if success flag = true redirects to the customer page
    if (this.state.successfullyAdded) {
      this.props.history.push("/customer");
    }
    return (
      <div>
        <form
          className="add-vehicle-form"
          onSubmit={this.handleSubmit}
          id="add-vehicle"
        >
          <Typography variant="h4" gutterBottom>
            Add Vehicle
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div className="bids-header-dropdown">
                <label
                  style={{
                    color: "#8E8E8E",
                    display: "block",
                    textAlign: "left",
                  }}
                >
                  Car Make *
                </label>
                <Select
                  required
                  id="carMaker"
                  name="carMaker"
                  classNamePrefix="select"
                  fullWidth
                  type="text"
                  options={this.state.carMakerList} // car meker list rendered in a  react-select component
                  onChange={(value) => {
                    this.handleChangeDropDown(value["value"]); // handle change dropdown is called to fetch the car models of required brand
                  }}
                  className={this.state.carMakeCheck ? "red-border-select" : ""}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="bids-header-dropdown">
                <label
                  style={{
                    color: "#8E8E8E",
                    display: "block",
                    textAlign: "left",
                  }}
                >
                  Car Model *
                </label>

                <Select
                  required
                  id="carModel"
                  name="carModel"
                  label="Car Model"
                  fullWidth
                  classNamePrefix="select"
                  type="text"
                  variant="standard"
                  autoComplete="family-name"
                  options={this.state.carModelList} // car models are rendered in the react-select
                  onChange={(value) => {
                    this.setState({
                      carModel: value["value"],
                    });
                  }}
                  className={
                    this.state.carModelCheck ? "red-border-select" : ""
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#8E8E8E",
                  display: "block",
                  textAlign: "left",
                }}
              >
                Year *
              </label>
              <Select
                id="year"
                name="year"
                classNamePrefix="select"
                styles={{
                  option: (styles) => ({
                    ...styles,
                    border: 0,
                    // This line disables the blue border
                    boxShadow: "none",
                  }),
                }}
                options={this.state.yearArray}
                onChange={(value) => {
                  this.setState({
                    year: value["value"],
                  });
                }}
                className={this.state.carYearCheck ? "red-border-select" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                error={this.state.carMileageCheck ? true : false}
                required
                id="mileage"
                name="mileage"
                label="Car Mileage"
                fullWidth
                value={
                  this.state.newCarCheck
                    ? (this.state.mileage = 0)
                    : this.state.mileage
                }
                autoComplete="family-name"
                onChange={this.handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={2} alignItems="center">
              <Checkbox
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onClick={() =>
                  this.setState({ newCarCheck: !this.state.newCarCheck })
                }
                checked={this.state.newCarCheck}
              />
              <label
                htmlFor="checkpass"
                onClick={() =>
                  this.setState({ newCarCheck: !this.state.newCarCheck })
                }
                style={{ color: "#8e8e8e" }}
              >
                New Car?
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ textAlign: "left" }}>
                <label style={{ color: "#8E8E8E" }}>State*</label>
                <Select
                  id="states"
                  name="states"
                  fullWidth
                  //   placeholder="State"
                  classNamePrefix="select"
                  styles={{
                    option: (styles) => ({
                      ...styles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",
                    }),
                  }}
                  options={[
                    { value: "Alabama", label: "Alabama" },
                    { value: "Alaska", label: "Alaska" },
                    { value: "Arizona", label: "Arizona" },
                    { value: "Arkansas", label: "Arkansas" },
                    { value: "California", label: "California" },
                    { value: "Colorado", label: "Colorado" },
                    { value: "Connecticut", label: "Connecticut" },
                    { value: "Delaware", label: "Delaware" },
                    { value: "Florida", label: "Florida" },
                    { value: "Georgia", label: "Georgia" },
                    { value: "Hawaii", label: "Hawaii" },
                    { value: "Illinois", label: "Illinois" },
                    { value: "Idaho", label: "Idaho" },
                    { value: "Indiana", label: "Indiana" },
                    { value: "Iowa", label: "Iowa" },
                    { value: "Kansas", label: "Kansas" },
                    { value: "Kentucky", label: "Kentucky" },
                    { value: "Louisiana", label: "Louisiana" },
                    { value: "Maine", label: "Maine" },
                    { value: "Maryland", label: "Maryland" },
                    { value: "Massachusetts", label: "Massachusetts" },
                    { value: "Michigan", label: "Michigan" },
                    { value: "Minnesota", label: "Minnesota" },
                    { value: "Mississippi", label: "Mississippi" },
                    { value: "Missouri", label: "Missouri" },
                    { value: "Montana", label: "Montana" },
                    { value: "Nebraska", label: "Nebraska" },
                    { value: "Nevada", label: "Nevada" },
                    { value: "New Hampshire", label: "New Hampshire" },
                    { value: "New Jersey", label: "New Jersey" },
                    { value: "New Mexico", label: "New Mexico" },
                    { value: "New York", label: "New York" },
                    { value: "North Carolina", label: "North Carolina" },
                    { value: "North Dakota", label: "North Dakota" },
                    { value: "Ohio", label: "Ohio" },
                    { value: "Oklahoma", label: "Oklahoma" },
                    { value: "Oregon", label: "Oregon" },
                    { value: "Pennsylvania", label: "Pennsylvania" },
                    { value: "Rhode Island", label: "Rhode Island" },
                    { value: "South Carolina", label: "South Carolina" },
                    { value: "South Dakota", label: "South Dakota" },
                    { value: "Tennessee", label: "Tennessee" },
                    { value: "Texas", label: "Texas" },
                    { value: "Utah", label: "Utah" },
                    { value: "Vermont", label: "Vermont" },
                    { value: "Virginia", label: "Virginia" },
                    { value: "Washington", label: "Washington" },
                    { value: "West Virginia", label: "West Virginia" },
                    { value: "Wisconsin", label: "Wisconsin" },
                    { value: "Wyoming", label: "Wyoming" },
                  ]}
                  onChange={(value) => {
                    //   console.log(value);
                    this.setState({
                      state: value["value"],
                    });
                  }}
                  className={this.state.stateCheck ? "red-border-select" : ""}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                id="zipcode"
                name="zipcode"
                label="Zip / Postal code"
                fullWidth
                autoComplete="shipping postal-code"
                onChange={this.handleChange}
                value={this.state.zipcode}
                error={this.state.zipcodeCheck}
                type="number"
              />
             {
               isZipcode(this.state.zipcode) ? "" : <p className="zipCode_error_check">Enter Valid Zipcode</p>
             }
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="comment"
                name="comment"
                label="Description"
                fullWidth
                type="text"
                variant="outlined"
                autoComplete="family-name"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  textAlign: "center",
                  display: "block",
                  margin: "auto",
                  background: "#01ACED",
                  color: "white",
                }}
                onClick={this.fieldsCheck}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default AddVehicle;
