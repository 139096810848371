import { Button, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import { IP } from "../../ip/IPConfig";
import "./Bids.css";
import "./PayPal1.css";
import PayPal1 from "./PayPal1";
import { Spinner1 } from "../../Spinners";
import swal from "sweetalert";
import { connect } from "react-redux";
import { customerLogin, customerLoginData } from "../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    customerQuotes: state.formSubmitReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginData: (data) => {
      dispatch(customerLogin(data));
    },
    afterLoginData: (data) => {
      dispatch(customerLoginData(data));
    },
  };
};

class Bids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // all quote details are stored in this array
      quoteDetail: [],

      // car quotes are stored in this list
      quoteList: [],

      // particular bid details are stored in this state
      bidList: null,

      // stores the value of selected quote
      quoteselected: "",

      // state changes to true when we click buy me coffee
      coffee: 0,

      // donation amount
      donation: 0,

      // search result after selecting a quote from the dropdown of car models
      result: "",

      list: "",
      selectedQuote: "",
    };
  }

  // this function is called when we click the button buy me a coffee
  clickedCoffee = () => {
    this.setState({
      coffee: !this.state.coffee,
      donation: 0,
    });

    // scrolls to top
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  // fetch bids of specific quote id
  fetchBid(id) {
    fetch(`${IP}/customer/quote/bid/${id}`, {
      headers: new Headers({
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        // response is stored in bidList
        this.setState({
          bidList: response,
        });
        console.log("bid list ", this.state.bidList);
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  }
  // componentDidMount() {
  //   window.scrollTo({ top: 0 });

  //   this.fetchBid(
  //     this.props.match.params.id == "1"
  //       ? this.state.list[0]["quote_id"]
  //       : this.props.match.params.id
  //   );

  //   this.props.onLoginData(JSON.parse(sessionStorage.getItem("loginResponse")));
  //   this.props.afterLoginData(JSON.parse(sessionStorage.getItem("carquotes")));

  //   console.log(sessionStorage.getItem("carquotes"));
  //   // if (this.state.list != "") {
  //   // we take the car quotes from the session storage and store them in states
  //   this.setState({
  //     quoteList: JSON.parse(sessionStorage.getItem("carquotes")),
  //   });

  //   const newList = JSON.parse(sessionStorage.getItem("carquotes"));

  //   this.state.list = newList.filter((item) => {
  //     return item.isactive == 1;
  //   });
  //   sessionStorage.setItem("bidsFirstQuote", this.state.list[0].car_maker);

  //   this.state.selectedQuote = newList.filter((item) => {
  //     return item.quote_id == this.props.match.params.id;
  //   });

  //   if (this.props.match.params.id == 1) {
  //     this.state.selectedQuote = [this.state.list[0]];
  //   }
  //   // }
  //   // then we fetch bid for specific quote
  // }

  componentDidMount() {
    window.scrollTo({ top: 0 });

    this.props.onLoginData(JSON.parse(sessionStorage.getItem("loginResponse")));
    this.props.afterLoginData(JSON.parse(sessionStorage.getItem("carquotes")));

    // if (this.props.match.params.id === "1") {
    // we take the car quotes from the session storage and store them in states
    this.setState({
      quoteList: JSON.parse(sessionStorage.getItem("carquotes")),
    });

    const newList = JSON.parse(sessionStorage.getItem("carquotes"));

    this.state.list = newList.filter((item) => {
      return item.isactive == 1;
    });

    if (this.state.list.length > 0) {
      sessionStorage.setItem("bidsFirstQuote", this.state.list[0].car_maker);

      this.state.selectedQuote = newList.filter((item) => {
        return item.quote_id == this.props.match.params.id;
      });

      if (this.props.match.params.id == "1") {
        this.state.selectedQuote = [this.state.list[0]];
      }
      
      // then we fetch bid for specific quote
      this.fetchBid(
        this.props.match.params.id === "1"
          ? this.state.list[0].quote_id
          : this.props.match.params.id
      );
    }else{
      this.fetchBid(
        this.props.match.params.id === "1"
          ? 0
          : this.props.match.params.id
      );
    }
  }

  // select handleChange function sets the selected quote in quoteSelected state then that car is searched in quoteList and stored in result
  handleChange = (event) => {
    this.setState({ bidList: null });
    try {
      this.setState({
        quoteselected: event.target.value,
      });
    } finally {
      this.fetchBid(event.target.value);
      this.setState({
        result: this.state.quoteList.find(
          ({ quote_id }) => quote_id === parseInt(event.target.value)
        ),
      });
      console.log(this.state.result);
    }
  };

  // this function is used to email all bids to the customer
  emailMeAllBids = () => {
    fetch(
      `${IP}/customer/email/bids/${this.state.quoteList[0].customer_id}/${this.state.bidList.results[0].quote_id}`, // backend api called to send all bids on specific quotes, sending email is handled by backend
      {
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        }),
      }
    )
      .then(async (res) => {
        const response = await res.text();
        console.log("Email me all bids response", response);
        swal("Email Sent", "To your registered Email-Id", "success"); // if success alert the user
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
        let error = err.toString();
        error = error.split(":");
        swal("Email Could Not Be Sent", error[1], "error");
      });
  };

  render() {
    return (
      <div>
        {this.state.quoteList !== null ? ( // if quotes are present then show quotes or else show spinner
          <div>
            <div className="bids-header">
              {this.state.selectedQuote === "" ? (
                <h4 className="bids-header-title">
                  {sessionStorage.getItem("bidsFirstQuote")}
                </h4>
              ) : (
                <div>
                  <h4 className="bids-header-title">
                    {this.state.selectedQuote == "" || this.state.result === ""
                      ? this.state.result == ""
                        ? this.state.selectedQuote[0].car_maker
                        : this.state.result.car_maker
                      : this.state.result.car_maker}
                  </h4>
                </div>
              )}
              <div className="bids-header-dropdown">
                <InputLabel htmlFor="age-native-simple">Car Model</InputLabel>
                <Select
                  value={this.state.quoteselected}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "quoteselected",
                    id: "age-native-simple",
                  }}
                  displayEmpty
                >
                  {this.state.quoteList.map((data, index) => {
                    return data.isactive == 1 ? (
                      <MenuItem
                        className="bids-header-dropdown-item"
                        aria-label={data["car_model"]}
                        value={
                          this.state.selectedQuote[0].quote_id === data.quote_id
                            ? ""
                            : data["quote_id"]
                        }
                      >
                        {this.state.selectedQuote[0].quote_id === data.quote_id
                          ? this.state.selectedQuote[0].car_model
                          : data["car_model"]}
                      </MenuItem>
                    ) : null;
                  })}
                </Select>
              </div>
            </div>

            {/* after clicking buy me coffee this component is rendered  */}
            {this.state.coffee ? (
              <div className="confirm-box">
                <div className="buttons-holder">
                  <p>*All entered amounts are in USD*</p>
                  <input
                    type="number"
                    placeholder="Donate Amount"
                    className="popup-input"
                    onChange={(event) =>
                      this.setState({
                        donation: event.target.value,
                      })
                    }
                  />
                  {this.state.donation === 0 ? null : (
                    <div>
                      <p className="pay-with-text ">&#9660; Pay With &#9660;</p>
                      <PayPal1 amount={this.state.donation} />{" "}
                      {/* paypal payment gateway component */}
                    </div>
                  )}
                  <button className="close-button" onClick={this.clickedCoffee}>
                    X Close
                  </button>
                </div>
              </div>
            ) : this.state.bidList !== null ? (
              <div>
                {this.state.bidList.results.length > 0 ? (
                  <div className="bid-list-card">
                    <div className="bid-cards-wrapper">
                      {this.state.bidList.results.map((data, index) => {
                        return (
                          <div className="bid-cards">
                            <div className="bid-cards-top">
                              <h5>{data.dealer_company}</h5>
                              <h6>{data.user_state}</h6>
                            </div>
                            <div className="bid-cards-bottom">
                              <span className="cards-bottom-span1">
                                Contact :-{" "}
                              </span>
                              <span>{data.user_phn}</span>
                              <p>
                                <span className="cards-bottom-span1">
                                  Bid Message :-{" "}
                                </span>
                              </p>
                              <p>{data.bids_message}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="bids-donate-btn">
                      <Button
                        className="email-btn"
                        onClick={this.emailMeAllBids}
                      >
                        Email me all bids
                      </Button>
                      <Button
                        className="donate-btn"
                        onClick={this.clickedCoffee}
                      >
                        Buy me a coffee
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="no-bids-yet-wrapper">
                    <div className="no-bid-inner-div">
                      <div className="no-bid-text-area">
                        <h3>
                          Our Dealers are working on the quotes. We will contact
                          you at{" "}
                          <span className="contact-details">
                            {sessionStorage.getItem("user_email")}
                          </span>{" "}
                          or{" "}
                          <span className="contact-details">
                            {sessionStorage.getItem("user_phone")}
                          </span>{" "}
                          as soon as they are available. To update contact
                          details{" "}
                          <a href="/customer/profile">
                            <i>click here</i>
                          </a>
                        </h3>
                      </div>
                      <div className="no-bid-img-area">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fwaiting.gif?alt=media&token=7ee12b42-32c9-4a08-b40e-6f805fc9dd8d"
                          className="no-bid-img"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Spinner1 className="bids-spinner" />
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="no-bids-yet-wrapper">
              <div className="no-bids-yet-inner-div">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2FNoBids.jpg?alt=media&token=1451e04c-4290-4cad-bfe8-a39a621e6853"
                  alt="image"
                />
                <h3>No Bids Applied, Wait For Response</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bids);
