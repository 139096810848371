
export function isEmail(value) {
  // const re =
  //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = 
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    // console.log(re.test(String(value).toLowerCase()) && value.includes(".com"))
  // return re.test(String(value).toLowerCase()) && value.includes(".com");
  
  return value.includes("@") && value.includes(".com");
}

export function isPhoneValid(value) {
  var res = /^[0-9\b]+$/;
  value = String(value);
  const isValid = res.test(value);
  if (value.length == 10 && isValid) {
    return true;
  } else if (value.length < 1) {
    return true;
  } else {
    return false;
  }
}

export function isZipcode(value) {

  var res = /^[0-9\b]+$/;
  value = String(value);
  const isValid = res.test(value);
  if (value.length == 5 && isValid) {
    return true;
  } else if (value.length < 1) {
    return true;
  } else {
    return false;
  }
}
