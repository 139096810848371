import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = "6LdUPOUgAAAAAHz5L7y2JAbVskPIfC5chrAz_ORk";

function Catpcha(props) {
  const recaptchaRef = React.createRef();

  const onChangeCaptcha = (value) =>{
    const res = recaptchaRef.current.getValue()
    console.log(res);
    props.onChangeCaptcha(res)
    recaptchaRef.current.reset();
  }

  return (
    <div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={SITE_KEY}
        onChange={onChangeCaptcha}
      />
    </div>
  );
}

export default Catpcha;
