//items to be showed in navbar for dealer navbar when loginRole is dealer

export const DealerMenuList = [
  {
    title: "INBOX",
    url: "/dealer",
  },
  {
    title: "SUBMITTED QUOTES",
    url: "/dealer/submittedquote",
  },
  {
    title: "QUOTE",
    url: "/dealer/quote/1",
  },
  {
    title: "INVOICE",
    url: "/dealer/invoice",
  },
  {
    title: "PROFILE",
    url: "/dealer/profile",
  },
  {
    title:'Logout',
    url:'/logout'
  }
];
