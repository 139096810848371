import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import React, { Component } from "react";
import { IP } from "../ip/IPConfig";
import "./RegistrationForm.css";
import Select from "react-select";
import Captcha from "./captcha";
import swal from "sweetalert";
import Checkbox from "@material-ui/core/Checkbox";
import { isEmail, isPhoneValid, isZipcode } from "../ValidationFunctions";
import { Redirect } from "react-router";
import { auth } from "../../firebase/firebase";
import firebase from "firebase";
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = "6LdUPOUgAAAAAHz5L7y2JAbVskPIfC5chrAz_ORk";
const SITE_KEY_v3 = "6LdRhuYgAAAAAKI0BIRSFOh7PY7Rb02h_8wX57hl";

// Registeration Form Class Component

export class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // states used to store the user information on sign up
      firstName: "",
      lastName: "",
      userName: "",
      carMaker: "",
      carModel: "",
      year: "",
      mileage: null,
      address: "",
      states: "",
      country: "United States",
      zipcode: "",
      phoneNumber: "",
      comments: "",
      emailId: "",
      newCarCheck: false,
      password: "",

      // loader state used to show the loading icon on sign up button
      loader: false,

      // stores the sign up response
      response: null,

      // stores years in array for year-select component in registration form
      yearArray: [],

      // states used to store car maker lis and car model list for select-component in registratio form
      carModelList: [],
      carMakerList: [],

      // captcha secure state
      recaptchaRef: React.createRef(),

      // redirects to the customer page on signup
      redirectTo: false,

      // vadlidation check state
      firstnameCheck: false,
      lastnameCheck: false,
      mileageCheck: false,
      zipcodeCheck: false,
      contactCheck: false,
      emailCheck: false,
      emailValidityCheck: false,
      carModelCheck: false,
      carMakeCheck: false,
      yearCheck: false,
      statesCheck: false,
      zipcodeCheck: false,
      zipcodeValidityCheck: false,
      recaptchaValue: null,
    };
  }

  // function used to get the list of car models of particular car maker from the server and called when we select the car maker in the car make of the form
  handleChangeDropDown = (e) => {
    // try {
    this.setState({
      carMaker: e,
    });
    // } finally {
    this.fetchCarModel(e);
    // }
  };

  // actual car model fectching using fetch function
  fetchCarModel(carModel) {
    this.state.carModelList = [];
    fetch(`${IP}/car/get/carmodel/${carModel}`)
      // after fetching api data is stored in car model list
      .then(async (res) => {
        var response = await res.json();
        response.map((data, index) => {
          this.state.carModelList[index] = {
            value: data.car_model,
            label: data.car_model,
          };
        });
      })
      // if any error occurs it is catched here
      .catch((err) => {
        console.log("error in fetching car models", err);
      });
  }

  // operations performed when the component loads
  componentDidMount() {
    // stores todays date
    const date = new Date();

    // used to store total no. of years from 2010 uptill now(getFullYear() returns current year in date object)
    let end = date.getFullYear() - 2010;

    // stores the years to display one by one in the yearArray and this yearArray is displayed in the year-select of registration form
    for (let index = 0; index <= end + 1; index++) {
      this.state.yearArray[index] = {
        value: (2010 + index).toString(),
        label: (2010 + index).toString(),
      };
    }

    this.state.yearArray = this.state.yearArray.reverse();

    // car maker is fetched from the server and stored in the carMakerList and displayed in car make-select of the form
    fetch(`${IP}/car/get/carmaker`, {
      method: "get",
    })
      .then(async (res) => {
        var response = await res.json();
        response.map((data, index) => {
          this.state.carMakerList[index] = {
            value: data.car_maker,
            label: data.car_maker,
          };
        });
      })
      .catch((err) => {
        console.log("error in customer add vehicle ", err);
      });

    // this.handleRecaptcha();
  }

  // all the values are stored in their corresponding states by this handle change function
  handleChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.name]:
        e.target.value < 0 ? e.target.value * -1 : e.target.value,
    });

    // all this if statements check the validity of the input fields and if not valid then false is returned and stored in check states

    if (this.state.firstName !== "") {
      this.setState({ firstnameCheck: false });
    }
    if (this.state.lastName !== "") {
      this.setState({ lastnameCheck: false });
    }
    if (this.state.mileage !== "") {
      this.setState({ mileageCheck: false });
    }
    if (this.state.zipcode !== "") {
      this.setState({ zipcodeCheck: false });
    }
    if (this.state.phoneNumber !== "") {
      this.setState({ contactCheck: false });
    }
    if (this.state.emailId !== "") {
      this.setState({ emailCheck: false });
    }
    if (this.state.zipcode !== "") {
      this.setState({ zipcodeCheck: false });
    }
  };

  // called when the signup button is clicked
  handleSubmit = async (e) => {
    e.preventDefault();

    // sets the loader until the response from server
    this.setState({
      loader: true,
    });

    if (this.state.recaptchaValue) {
      console.log("inside if");
      this.submitData(this.state.recaptchaValue);
    } else {
      this.submitData(this.state.recaptchaValue);
    }
  };

  // all the data in the input fields is submitted to the server
  submitData = (token) => {
    // call a backend API to verify reCAPTCHA response
    if (
      isPhoneValid(this.state.phoneNumber) &&
      isEmail(this.state.emailId) &&
      isZipcode(this.state.zipcode) &&
      this.state.carMaker !== "" &&
      this.state.carModel !== "" &&
      this.state.year !== "" &&
      this.state.states !== ""
    ) {
      fetch(`${IP}/customer/signupcustomer/addvehicle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // all the stored values are posted to the backend via api
          user_firstname: this.state.firstName,
          user_lastname: this.state.lastName,
          user_email: [this.state.emailId], // email needs to be sent in array
          user_password: this.state.password,
          user_address: this.state.address,
          user_state: this.state.states,
          user_pincode: this.state.zipcode,
          user_phn: this.state.phoneNumber,
          user_country: "USA",
          carMaker: this.state.carMaker,
          carModel: this.state.carModel,
          year: this.state.year,
          mileage: this.state.mileage,
          carNew: true,
          comments: this.state.comments,
        }),
      })
        .then(async (res) => {
          var response_json = await res.text();
          if (response_json.startsWith("{")) {
            // when text response starts with "{" we parse it in JSOn format
            response_json = JSON.parse(response_json);
            sessionStorage.setItem("token", response_json["token"]); // we store the token in session storage for further api calls
            sessionStorage.setItem("role", response_json["role"]); // role is stored in session to identify users role

            // additional user info stored in session for use in other components
            sessionStorage.setItem(
              "user_name",
              response_json["info"][0]["user_firstname"]
            );
            // console.log("response : ", response_json["result"][0]);
            sessionStorage.setItem(
              "user_id",
              response_json["info"][0]["user_id"]
            );
            sessionStorage.setItem(
              "customerId",
              response_json["info"][0]["customer_id"]
            );
            sessionStorage.setItem(
              "user_email",
              response_json["info"][0]["user_email"]
            );
            sessionStorage.setItem(
              "user_phone",
              response_json["info"][0]["user_phn"]
            );
            // redirect is set true so after response we get directed to the customer dashboard/quotes
            setTimeout(() => {
              firebase
                .auth()
                .signInWithEmailAndPassword(
                  response_json["info"][0]["user_email"],
                  this.state.password
                )
                .then((userCredential) => {
                  auth.currentUser.getIdToken(true).then((idToken) => {
                    sessionStorage.setItem("idToken", idToken);
                    this.setState({
                      redirectTo: true,
                    });
                    window.location.reload(); // reload is required to render the customer page
                    this.setState({
                      loader: false,
                    });
                  });
                  // Signed in
                  var user = userCredential.user;
                  // ...
                })
                .catch((error) => {
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  this.setState({
                    loader: false,
                  });
                });
            }, 2000);
          } else {
            // show info if the email is already registered
            swal({
              title: "Email Id Already Registered",
              text: "Try Logging In",
              icon: "info",
              button: "Okay",
            });
            this.setState({
              loader: false,
            });
          }

          // after that loader is set to false
          this.setState({
            response: response_json,
          });
          // window.location.reload();
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      this.setState({
        loader: false,
      });
      document.getElementById("reg-from").scrollIntoView();
    }
  };

  onChangeCaptcha(value) {
    console.log(value);
    this.setState({ recaptchaValue: true });
  }

  // checks all the fields to highlight the missing field in red
  fieldsCheck = () => {
    if (this.state.firstName == "") {
      this.setState({ firstnameCheck: true });
    } else if (this.state.lastName == "") {
      this.setState({ lastnameCheck: true });
    } else if (this.state.carMaker == "") {
      this.setState({ carMakeCheck: true });
      document.getElementById("reg-form").scrollIntoView();
    } else if (this.state.carModel == "") {
      this.setState({ carModelCheck: true });
      document.getElementById("reg-form").scrollIntoView();
    } else if (this.state.year == "") {
      this.setState({ yearCheck: true });
      document.getElementById("reg-form").scrollIntoView();
    } else if (this.state.states == "") {
      this.setState({ statesCheck: true });
      document.getElementById("reg-form").scrollIntoView();
    } else if (this.state.mileage == null) {
      this.setState({ mileageCheck: true });
    } else if (this.state.zipcode == "") {
      this.setState({ zipcodeCheck: true });
    } else if (this.state.phoneNumber == "") {
      this.setState({ contactCheck: true });
    } else if (this.state.emailId == "") {
      this.setState({ emailCheck: true });
    } else {
      this.setState({ emailCheck: false });
    }
  };

  render() {
    // if all the fields in form are correct redirect to customer
    if (this.state.redirectTo === true) {
      return <Redirect to="/customer" />;
    }

    if (!isEmail(this.state.emailId) && this.state.emailId.length > 0) {
      this.state.emailValidityCheck = true;
    } else {
      this.state.emailValidityCheck = false;
    }

    if (!isZipcode(this.state.zipcode) && this.state.zipcode.length > 0) {
      this.state.zipcodeValidityCheck = true;
    } else {
      this.state.zipcodeValidityCheck = false;
    }

    if (this.state.mileage == 0) {
      this.state.mileageCheck = false;
    }
    if (this.state.carMaker !== "") {
      this.state.carMakeCheck = false;
    }
    if (this.state.carModel !== "") {
      this.state.carModelCheck = false;
    }
    if (this.state.year !== "") {
      this.state.yearCheck = false;
    }
    if (this.state.states !== "") {
      this.state.statesCheck = false;
    }

    return (
      <div className="reg-form-card" id="reg-form">
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={2} style={{ paddingTop: "2%" }}>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                error={this.state.firstnameCheck}
                id="firstName"
                name="firstName"
                label="First Name"
                fullWidth
                value={this.state.firstName}
                type="text"
                variant="outlined"
                size="small"
                autoComplete="family-name"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                error={this.state.lastnameCheck}
                value={this.state.lastName}
                id="lastName"
                name="lastName"
                label="Last Name"
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                autoComplete="family-name"
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#8E8E8E",
                  display: "block",
                  textAlign: "left",
                }}
              >
                Car Make *
              </label>
              <Select
                required
                id="carMaker"
                name="carMaker"
                label="Car Maker"
                classNamePrefix="select"
                fullWidth
                type="text"
                variant="standard"
                autoComplete="family-name"
                options={this.state.carMakerList}
                onChange={(value) => {
                  this.handleChangeDropDown(value["value"]);
                }}
                className={this.state.carMakeCheck ? "red-border-select" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#8E8E8E",
                  display: "block",
                  textAlign: "left",
                }}
              >
                Car Model *
              </label>
              <Select
                required
                id="carModel"
                name="carModel"
                label="Car Model"
                fullWidth
                type="text"
                variant="standard"
                classNamePrefix="select"
                autoComplete="family-name"
                options={this.state.carModelList}
                onChange={(value) => {
                  this.setState({
                    carModel: value["value"],
                  });
                }}
                className={this.state.carModelCheck ? "red-border-select" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#8E8E8E",
                  display: "block",
                  textAlign: "left",
                }}
              >
                Year *
              </label>
              <Select
                id="year"
                name="year"
                classNamePrefix="select"
                styles={{
                  option: (styles) => ({
                    ...styles,
                    border: 0,
                    // This line disables the blue border
                    boxShadow: "none",
                  }),
                }}
                options={this.state.yearArray}
                onChange={(value) => {
                  this.setState({
                    year: value["value"],
                  });
                }}
                className={this.state.yearCheck ? "red-border-select" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12} sm={12}>
                <label
                  style={{
                    color: "#8E8E8E",
                    display: "block",
                    textAlign: "left",
                  }}
                >
                  Mileage *
                </label>{" "}
                <TextField
                  required
                  error={this.state.mileageCheck}
                  id="mileage"
                  name="mileage"
                  // label="Mileage"
                  fullWidth
                  value={
                    this.state.newCarCheck
                      ? (this.state.mileage = 0)
                      : this.state.mileage
                  }
                  type="number"
                  variant="outlined"
                  size="small"
                  autoComplete="family-name"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="checkpass"
                  onClick={() =>
                    this.setState({ newCarCheck: !this.state.newCarCheck })
                  }
                  style={{ color: "#8e8e8e" }}
                >
                  New Car?
                </label>
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={() =>
                    this.setState({ newCarCheck: !this.state.newCarCheck })
                  }
                  checked={this.state.newCarCheck}
                  style={{ padding: "0px" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div style={{ textAlign: "left" }}>
                <label style={{ color: "#8E8E8E" }}>State*</label>
                <Select
                  id="states"
                  name="states"
                  fullWidth
                  //   placeholder="State"
                  classNamePrefix="select"
                  styles={{
                    option: (styles) => ({
                      ...styles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",
                    }),
                  }}
                  options={[
                    { value: "Alabama", label: "Alabama" },
                    { value: "Alaska", label: "Alaska" },
                    { value: "Arizona", label: "Arizona" },
                    { value: "Arkansas", label: "Arkansas" },
                    { value: "California", label: "California" },
                    { value: "Colorado", label: "Colorado" },
                    { value: "Connecticut", label: "Connecticut" },
                    { value: "Delaware", label: "Delaware" },
                    { value: "Florida", label: "Florida" },
                    { value: "Georgia", label: "Georgia" },
                    { value: "Hawaii", label: "Hawaii" },
                    { value: "Illinois", label: "Illinois" },
                    { value: "Idaho", label: "Idaho" },
                    { value: "Indiana", label: "Indiana" },
                    { value: "Iowa", label: "Iowa" },
                    { value: "Kansas", label: "Kansas" },
                    { value: "Kentucky", label: "Kentucky" },
                    { value: "Louisiana", label: "Louisiana" },
                    { value: "Maine", label: "Maine" },
                    { value: "Maryland", label: "Maryland" },
                    { value: "Massachusetts", label: "Massachusetts" },
                    { value: "Michigan", label: "Michigan" },
                    { value: "Minnesota", label: "Minnesota" },
                    { value: "Mississippi", label: "Mississippi" },
                    { value: "Missouri", label: "Missouri" },
                    { value: "Montana", label: "Montana" },
                    { value: "Nebraska", label: "Nebraska" },
                    { value: "Nevada", label: "Nevada" },
                    { value: "New Hampshire", label: "New Hampshire" },
                    { value: "New Jersey", label: "New Jersey" },
                    { value: "New Mexico", label: "New Mexico" },
                    { value: "New York", label: "New York" },
                    { value: "North Carolina", label: "North Carolina" },
                    { value: "North Dakota", label: "North Dakota" },
                    { value: "Ohio", label: "Ohio" },
                    { value: "Oklahoma", label: "Oklahoma" },
                    { value: "Oregon", label: "Oregon" },
                    { value: "Pennsylvania", label: "Pennsylvania" },
                    { value: "Rhode Island", label: "Rhode Island" },
                    { value: "South Carolina", label: "South Carolina" },
                    { value: "South Dakota", label: "South Dakota" },
                    { value: "Tennessee", label: "Tennessee" },
                    { value: "Texas", label: "Texas" },
                    { value: "Utah", label: "Utah" },
                    { value: "Vermont", label: "Vermont" },
                    { value: "Virginia", label: "Virginia" },
                    { value: "Washington", label: "Washington" },
                    { value: "West Virginia", label: "West Virginia" },
                    { value: "Wisconsin", label: "Wisconsin" },
                    { value: "Wyoming", label: "Wyoming" },
                  ]}
                  onChange={(value) => {
                    //   console.log(value);
                    this.setState({
                      states: value["value"],
                    });
                  }}
                  className={this.state.statesCheck ? "red-border-select" : ""}
                />
              </div>
            </Grid>
            {/* <Grid sm={6}></Grid> */}
            <Grid item xs={6} sm={6}>
              <label
                style={{
                  color: "#8E8E8E",
                  display: "block",
                  textAlign: "left",
                }}
              >
                Country*
              </label>{" "}
              <TextField
                required
                id="country"
                name="country"
                // label="Country"
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                style={{ marginTop: "0%" }}
                autoComplete="family-name"
                onChange={this.handleChange}
                disabled
                value={this.state.country}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                error={this.state.zipcodeCheck}
                id="zipcode"
                name="zipcode"
                label="Zip Code"
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                value={this.state.zipcode}
                autoComplete="family-name"
                onChange={this.handleChange}
              />
              {this.state.zipcodeValidityCheck ? (
                <p className="validation-error">Enter valid zipcode</p>
              ) : null}
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                error={this.state.contactCheck}
                id="phoneNumber"
                name="phoneNumber"
                label="Contact Number"
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                value={this.state.phoneNumber}
                autoComplete="family-name"
                onChange={this.handleChange}
              />
              {isPhoneValid(this.state.phoneNumber) ? null : (
                <p className="validation-error">Enter valid mobile number</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="comments"
                name="comments"
                label="Comment"
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                value={this.state.comments}
                autoComplete="family-name"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                error={this.state.emailCheck}
                onChange={this.handleChange}
                name="emailId"
                required
                fullWidth
                value={this.state.emailId}
                type="email"
                label="Email Address"
                variant="outlined"
                size="small"
              />
              {this.state.emailValidityCheck ? (
                <p className="validation-error">Enter valid email</p>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="password"
                name="password"
                label="Password"
                fullWidth
                type="password"
                variant="outlined"
                size="small"
                onChange={this.handleChange}
                helperText={
                  this.state.password !== "" && this.state.password.length < 6
                    ? "Password must have atleast 6 characters"
                    : null
                }
                error={
                  this.state.password !== "" && this.state.password.length < 6
                }
              />
            </Grid>
          </Grid>
          <div className="captcha-wrapper">
            {/* <Captcha onChangeCaptcha={this.onChangeCaptcha} /> */}
            <ReCAPTCHA
              ref={this.recaptchaRef}
              sitekey={SITE_KEY}
              onChange={this.onChangeCaptcha}
            />
          </div>

          <Button
            className="signup-btn"
            type="submit"
            onClick={this.fieldsCheck}
            // onClick={this.handleSubmit}
            disabled={this.state.loader || this.state.password.length < 6}
          >
            SignUp
            <span style={{ margin: "0% 2%" }}>
              {this.state.loader ? (
                <CircularProgress size="1.5rem" style={{ color: "white" }} />
              ) : (
                <></>
              )}
            </span>
          </Button>
        </form>
      </div>
    );
  }
}

export default RegistrationForm;
