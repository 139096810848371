import React, { useState } from "react";
import ReactDOM from "react-dom";
import './PayPal1.css';

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
const style = {layout:'horizontal',shape:'pill',color:'blue',label:'paypal'}

const PayPal1 = props => { // we get the donation amount in the props

  let [error, setError] = useState(null);
  let [order, setOrder] = useState(null);
  
  // we create a order using create order function
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: props.amount,
          },
        },
      ],
    });
  }

  // this function is called after the payment is approved and all the details are stored in order state
  const onApprove = (data, actions) => {

    setOrder(order);
    return actions.order.capture();
  }

  // this method is called when we cancel the transaction
  const onCancel = (data) => {
    
    console.log(data);
  }
  
  // this method is called when there an error in the transaction
  const onError = (err) => {
    
    setError(err);
    console.log(err);
  }

  // this method is called when the transaction is successfull
  const onSuccess = (payment) => {

    console.log(payment);
  }

  return (
    <div className="paypal-wrap">
      {
        error == null ?
          order == null ?
            // using the paypal button API from paypal developer refrence link https://developer.paypal.com/docs/business/checkout/configure-payments/single-page-app/
            <PayPalButton
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
              onCancel={(data) => onCancel(data)}
              onError={(err) => onError(err)}
              onSuccess={(payment) => onSuccess(payment)}
              style={style}
            />
            :
            <div className="transaction-status1">
              Transaction Successful !!
            </div>
          :
          <div className="transaction-status2">
            Transaction Failed !!
          </div>
      }
    </div>
  );
}
export default PayPal1;