import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import "../submittedquotes/submitted.css";
import { Link, Redirect } from "react-router-dom";
import "./Inbox.css";
import { IP } from "../../../ip/IPConfig";
import { Button, Divider } from "@material-ui/core";
import service1 from "../../../images/service1.webp";
import moment from "moment";
import Pagination from "../../../Pagination";
import * as _ from "lodash";
import { Spinner1 } from "../../../Spinners";

const Inbox = () => {
  // car quotes array
  let [carquotes, setcarquotes] = useState(null);

  // search input is stored in this state
  const [search, setSearch] = useState("");

  // dealer approve status state
  const [approve, setApprove] = useState(false);

  // pagination constant decides how many quotes to show per page
  const [showPerPage, setShowPerPage] = useState(8);

  // pagination object
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  // on page change start and end are updated
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  // check the status of approved
  useEffect(() => {
    console.log(sessionStorage.getItem("approved"));
    if (sessionStorage.getItem("approved") === "pending") {
      setApprove(true);
    }
  });

  // fetch car quotes whenever page renders
  useEffect(async () => {
    await fetchCarQuotes();
  }, []);

  // handle change sets search value intoo seacr variable
  const handleChange = (e) => {
    console.log("change", e.target.value);
    if (e.target.value === "") {
      fetchCarQuotes();
    }
    setSearch(e.target.value);
  };

  // fetch car quotes
  async function fetchCarQuotes() {
    window.scrollTo({ top: 0 });

    await fetch(`${IP}/dealer/quotes/${sessionStorage.getItem("dealer_id")}`, {
      headers: new Headers({
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        console.log("quotes data ", response);
        setcarquotes(_.sortBy(response, "timestamp").reverse()); // set car quotes
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  }

  // search function search quotes from the server via API
  function handleSearchClick(e) {
    e.preventDefault();
    console.log("search ", search);
    fetch(`${IP}/dealer/search/quotes/${search}`, {
      method: "GET",
      headers: new Headers({
        // authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        setcarquotes(response); // store the response in the carquotes
        console.log("response : ", response);
      })
      .catch((err) => {
        console.log("error in fetching search  ", err);
      });
  }

  //sort the array by either time stamp or brand
  const sortArray = (type) => {
    const types = {
      Date: "timestamp",
      Brand: "car_maker",
    };
    const sortProperty = types[type];
    let sorted = _.sortBy(carquotes, sortProperty);
    if (types[type] == "timestamp") {
      sorted = sorted.reverse();
    }
    setcarquotes(sorted);
  };

  // if not approved then redirect to waiting page
  if (approve) {
    return (
      <div>
        <Redirect to="/dealer/waiting" />
      </div>
    );
  }

  console.log(sessionStorage.getItem("idToken"))

  return (
    <div>
      <div className="dealer-hero-page">
        <h2 className="dealer-hero-heading">
          Welcome {sessionStorage.getItem("dealer_name")}
          {"!"}
        </h2>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fservice1.webp?alt=media&token=f90d9e81-f167-44ff-a2d1-351f2112a4d2"
          className="service-image"
        ></img>
      </div>
      <Divider variant="middle" />
      <div className="search-box">
        <TextField
          variant="outlined"
          placeholder="Search quotes by quote I'd , Email , Phone"
          className="inbox-search"
          type="search"
          id="search"
          name="search"
          onChange={handleChange}
          size="small"
          color="blue"
          InputProps={{
            endAdornment: (
              <Button
                className="search-btn"
                value={search}
                onClick={handleSearchClick}
              >
                Search
              </Button>
            ),
          }}
        />
        <div className="sorting-div">
          <h5>Sort By : </h5>
          <select
            onChange={(e) => sortArray(e.target.value)}
            className="sort-select"
          >
            <option value="select">Select value</option>
            <option value="Date">Date</option>
            <option value="Brand">Brand</option>
          </select>
        </div>
      </div>
      {carquotes != null ? ( // if car quotes is null then show a spinner
        <div>
          <div className="quotes-card-main-container">
            {carquotes.length > 0 ? ( // if true show the data else show
              carquotes.slice(pagination.start, pagination.end).map((data) => {
                // slice the data and show only 8 on one page
                return (
                  <div className="quotes-card-container">
                    <div className="quote-card-stack">
                      <div className="quote-card-stack-text">
                        <div>
                          {/* <h5>Car Maker</h5> */}
                          <small>{data.car_maker}</small>

                          {/* <h5>Car Model</h5> */}
                          <h5>{data.car_model}</h5>
                        </div>
                        {data.car_mileage == 0 ? (
                          <div className="quote-card-stack-img">
                            {/* <img src={newcar} alt="New Car" /> */}
                            <p>New Car</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="quote-card-stack-down">
                      <div style={{ textAlign: "right", margin: "3% 0%" }}>
                        <small style={{ marginTop: "3%" }}>
                          {moment(data.timestamp).fromNow()}
                        </small>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          Quote Id : {data.quote_id}
                        </div>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/color/30/000000/cruise-control-on.png" />
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          Mileage
                        </div>
                        <div style={{ marginTop: "3%", marginLeft: "5%" }}>
                          <h6>{data.car_mileage}</h6>
                        </div>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/color/30/000000/google-maps.png" />
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          State
                        </div>
                        <div
                          style={{
                            marginTop: "3%",
                            marginLeft: "5%",
                          }}
                        >
                          <h6>{data.car_state}</h6>
                        </div>
                      </div>
                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/color/30/000000/place-marker--v2.png" />{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          Zip code
                        </div>
                        <div style={{ marginTop: "3%", marginLeft: "5%" }}>
                          <h6>{data.car_zipcode}</h6>
                        </div>
                      </div>

                      <div
                        className="icon-detail"
                        style={{
                          display: "flex",
                          margin: "3% 5%",
                          marginLeft: "5%",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img src="https://img.icons8.com/ios/30/000000/expensive-2--v2.png" />{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            marginTop: "3%",
                          }}
                        >
                          Price
                        </div>
                        <div style={{ marginTop: "3%", marginLeft: "5%" }}>
                          <h6>{"$" + data.car_price}</h6>
                        </div>
                      </div>

                      <div className="buttons-div">
                        <Link to={`/dealer/quote/${data["quote_id"]}`}>
                          <button>Quote</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <center>
                  <h5 style={{ marginTop: "5%" }}>No result found</h5>
                </center>
              </div>
            )}
          </div>
          <Pagination // pagination component
            showPerPage={showPerPage}
            onPaginationChange={onPaginationChange}
            total={carquotes.length}
          />
        </div>
      ) : (
        <Spinner1 />
      )}
    </div>
  );
};
export default Inbox;
