import React, { useEffect, useState } from "react";
import { IP } from "../../../ip/IPConfig";
import "./Profile.css";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Done";
import {
  Button,
  DialogContentText,
  Grid,
  TextField,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
} from "@material-ui/core";
import { Spinner1 } from "../../../Spinners";
import Select from "react-select";
import { firebasecode } from "../../../../firebase/firebase";
import { isPhoneValid, isZipcode } from "../../../ValidationFunctions";

const Profile = () => {
  const initialState = "";

  // user details states
  const [firstName, setfirstName] = useState(initialState);
  const [lastName, setlastName] = useState(initialState);
  const [dealershipName, setdealershipName] = useState(initialState);
  const [address, setaddress] = useState(initialState);
  const [phoneNumber, setphoneNumber] = useState(initialState);
  let [dealerBrand, setdealerBrand] = useState([]);
  const [state, setstate] = useState(initialState);
  const [zipcode, setzipcode] = useState(initialState);
  const [emailId, setemailId] = useState(initialState);
  const [userName, setuserName] = useState(initialState);
  const [userDetails, setuserDetails] = useState(null);
  const [country, setcountry] = useState(initialState);
  const [profileImgUrl, setprofileImgUrl] = useState(initialState);
  const [emailArray, setEmailArray] = useState(null);
  const [alternateEmails, setAlternateEmails] = useState([]);
  let [brandUpdate, setBrandUpdate] = useState([]);

  // edit user details flag
  const [edit, setedit] = useState(false);

  // alert flag
  const [alert, setalert] = useState(false);

  // change flag set when a profile detail is edited
  const [change, setchange] = useState(0);

  // profile pic edit flag used to check if profile image button is clicked
  const [editImage, setEditImage] = useState(false);

  // profile pic is stored in this state
  const [profilePic, setProfilePic] = useState(null);

  // flag for profile if pic is present
  const [profilePicFlag, setProfilePicFlag] = useState(false);

  // used to set the image loader icon till the pic is updated
  const [imageLoader, setimageLoader] = useState(false);

  //car brands from server
  const [carBrandNames, setCarBrandNames] = useState([]);

  // firebase
  const db = firebasecode.firestore();
  const storage = firebasecode.storage();

  // fetching user details
  useEffect(async () => {
    fetch(`${IP}/car/get/carmaker`)
      .then(async (res) => {
        var response = [];
        response = await res.json();
        console.log(response);
        response.forEach((element) => {
          var carBrand_ = {
            value: element["car_maker"],
            label: element["car_maker"],
          };
          setCarBrandNames((carBrandNames) => [...carBrandNames, carBrand_]); // set car brands in state
        });
      })
      .catch((err) => {
        console.log("====================================");
        console.log("error in fetchig cars");
        console.log("====================================");
      });

    await fetch(
      `${IP}/dealer/bid/viewprofile/${sessionStorage.getItem("dealer_id")}`,
      {
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        console.log("user data ", response);

        // set the response in user details
        setuserDetails(response);

        // set all the states
        setfirstName(response.userdetails[0]["user_firstname"]);
        setlastName(response.userdetails[0]["user_lastname"]);
        setdealershipName(response.userdetails[0]["dealer_company"]);
        setaddress(response.userdetails[0]["user_address"]);
        setphoneNumber(response.userdetails[0]["user_phn"]);
        setdealerBrand(response.brands);
        setstate(response.userdetails[0]["user_state"]);
        setzipcode(response.userdetails[0]["user_pincode"]);
        setemailId(response.userdetails[0]["user_email"]);
        setuserName(response.userdetails[0]["user_name"]);
        setcountry(response.userdetails[0]["user_country"]);
        setprofileImgUrl(response.userdetails[0]["user_image_url"]);
        setEmailArray(response.emails);
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  }, []);

  // function called when we cancel the edit profile option
  const handleClose = () => {
    // all the changed values are reverted back to normal on cancel
    setfirstName(userDetails.userdetails[0]["user_firstname"]);
    setlastName(userDetails.userdetails[0]["user_lastname"]);
    setdealershipName(userDetails.userdetails[0]["dealer_company"]);
    setaddress(userDetails.userdetails[0]["user_address"]);
    setphoneNumber(userDetails.userdetails[0]["user_phn"]);
    setdealerBrand(userDetails.brands);
    setstate(userDetails.userdetails[0]["user_state"]);
    setzipcode(userDetails.userdetails[0]["user_pincode"]);
    setemailId(userDetails.userdetails[0]["user_email"]);
    setuserName(userDetails.userdetails[0]["user_name"]);
    setcountry(userDetails.userdetails[0]["user_country"]);
    setprofileImgUrl(userDetails.userdetails[0]["user_image_url"]);
    setEmailArray(userDetails.emails);
    setedit(false);
    setalert(false);
  };

  // handle change uses to take the input values & store them into respective states
  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setchange(1);
      setfirstName(e.target.value);
    } else if (e.target.name === "lastName") {
      setchange(1);
      setlastName(e.target.value);
    } else if (e.target.name === "dealershipName") {
      setchange(1);
      setdealershipName(e.target.value);
    } else if (e.target.name === "address") {
      setchange(1);
      setaddress(e.target.value);
    } else if (e.target.name === "phoneNumber") {
      setchange(1);
      setphoneNumber(e.target.value);
    } else if (e.target.name === "dealerBrand") {
      setchange(1);
      setdealerBrand(e.target.value);
    } else if (e.target.name === "state") {
      setchange(1);
      setstate(e.target.value);
    } else if (e.target.name === "zipcode") {
      setchange(1);
      setzipcode(e.target.value);
    } else if (e.target.name === "emailId") {
      setchange(1);
      setemailId(e.target.value);
    } else if (e.target.name === "userName") {
      setchange(1);
      setuserName(e.target.value);
    } else if (e.target.name === "profileImageUrl") {
      setchange(1);
      setprofileImgUrl(e.target.value);
    }
  };

  // called when we click the close( X ) button in edit profile option
  const alterEdit = (e) => {
    if (change != 0) {
      setalert(true);
    } else {
      setedit(false);
    }
  };

  // called when we click the edit button
  const alterEditTrue = (e) => {
    setedit(true); // set edit flag true to show the textfields to update the values
  };

  // handle update function updates the user information to the server
  const handleUpdate = (e) => {
    console.log(firstName);

    if (
      phoneNumber.trim() !== "" &&
      zipcode.trim() !== "" &&
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      emailId.trim() !== "" &&
      state.trim() !== "" &&
      isZipcode(zipcode) &&
      isPhoneValid(phoneNumber)
    ) {
      // shows loader when the image is being uploaded
      if (editImage) {
        setimageLoader(true);
      }

      //taking brand_id only from dealerBrand object
      let brandId = [];
      dealerBrand.map((data) => {
        let tempArr = [];
        tempArr.push(data.brand_id);
        brandId = [...brandId, ...tempArr];
      });

      brandUpdate = brandUpdate.filter(
        (val, id, array) => array.indexOf(val) == id
      );

      console.log("In handle update");

      // logic to only update the profile picture
      // if profile pic flag is set true
      if (profilePicFlag) {
        // firebase storage ref
        var storageRef = storage.ref();

        // uploading image to firebase storage
        var spaceRef = storageRef.child(`images/${profilePic["name"]}`);
        var url; // var to store the url
        spaceRef
          .put(profilePic) // after adding to storage
          .then(async (res) => {
            url = await spaceRef.getDownloadURL(); // this function gives us the image url from the firebase storage

            // after the url is generated we update the dealer profile and add the new profile pic url to the server
            fetch(`${IP}/dealer/update/add/email/brand/`, {
              method: "PATCH",
              headers: new Headers({
                // authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
                authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
                "Content-Type": "application/json",
              }),
              body: JSON.stringify({
                user_firstname: firstName,
                user_lastname: lastName,
                user_name: userName,
                user_email: alternateEmails,
                user_phn: phoneNumber,
                dealer_company: dealershipName,
                dealer_brand: brandUpdate,
                brand_id: brandId,
                user_address: address,
                user_state: state,
                user_pincode: zipcode,
                user_country: country,
                dealer_id: sessionStorage.getItem("dealer_id"),
                user_id: sessionStorage.getItem("user_id"),
                user_image_url: url, // updated url is passsed here
              }),
            })
              .then(async (res) => {
                const response = await res;
                setedit(false); // make edit profile false
                setalert(false); // make alert false
                setimageLoader(false); // set image loader to flase to stop loading and display image
                setprofileImgUrl(await url); // add image url in state to render the image
                console.log("response : ", response);
                window.location.reload(); // reload so that we get the updated info
              })
              .catch((err) => {
                console.log("err in update ", err);
              });
          })
          .catch((err) => {
            console.log("error while uploading", err);
          });
      } else {
        // else normal data update tomthe server

        // dealer update post to server
        fetch(`${IP}/dealer/update/add/email/brand/`, {
          method: "PATCH",
          headers: new Headers({
            authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_firstname: firstName,
            user_lastname: lastName,
            user_name: userName,
            user_email: alternateEmails,
            user_phn: phoneNumber,
            dealer_company: dealershipName,
            dealer_brand: brandUpdate,
            brand_id: brandId,
            user_address: address,
            user_state: state,
            user_pincode: zipcode,
            user_country: country,
            dealer_id: sessionStorage.getItem("dealer_id"),
            user_id: sessionStorage.getItem("user_id"),
            user_image_url: profileImgUrl,
          }),
        })
          .then(async (res) => {
            const response = await res;
            setedit(false);
            setalert(false);
            setimageLoader(false);
            console.log("response : ", response);
            sessionStorage.setItem("dealer_name", firstName);
            window.location.reload();
          })
          .catch((err) => {
            console.log("err in update ", err);
          });
      }
    } else {
      window.alert("Enter proper values");
    }
    // e.preventDefault();
  };

  // called when we click the save icon
  const saveEditImage = () => {
    setEditImage(false);
    handleUpdate();
  };

  // ********************************Delete Brand************************************

  // deleteBrand takes one argument brand_id and deletes the associated brand from the server
  const deleteBrand = (brandId) => {
    // dynamically remove dealer brand from the page
    let tempIdArr = [];
    dealerBrand.map((data) => {
      if (data.brand_id == brandId) {
      } else {
        tempIdArr.push(data);
      }
    });

    setdealerBrand(tempIdArr); // set the new array to the rendered array

    // POST api call to delete the brand from server
    fetch(`${IP}/dealer/delete/brand/${brandId}`, {
      method: "PATCH",
      headers: new Headers({
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // *************************************************************************

  // ***************************Add alternate EmailIds*******************************

  // stores email input
  const [emailInput, setEmailInput] = useState("");

  // deleteEmail function to delete alternate email-ids takes email_id as arguments
  const deleteEmail = (id) => {
    let arr = [];

    // dynamically change the email id from the page
    emailArray.map((data) => {
      if (data.id == id) {
      } else {
        arr.push(data);
      }
    });
    setEmailArray(arr); // set email array with new values

    console.log(id);

    // POST api call to delete the email id from the server
    fetch(`${IP}/dealer/delete/email/${id}`, {
      method: "PATCH",
      headers: new Headers({
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      }),
    })
      .then(async (res) => {
        const response = await res.text();
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // add email to alternate email array
  let arr1 = [];
  const addEmail = () => {
    // if email id is present already then its not added
    if (emailArray.find((obj) => obj.dealer_email === emailInput)) {
    } else {
      // else add email id
      arr1.push(emailInput);
      setAlternateEmails([...alternateEmails, ...arr1]);
      setEmailInput("");
    }
  };

  // delete alternate emails from the array locally and then update that array to the server
  const deleteAlternateEmail = (index) => {
    let arr = [];
    alternateEmails.map((data) => {
      if (alternateEmails.indexOf(data) == index) {
      } else {
        arr.push(data);
      }
    });
    setAlternateEmails(arr);
  };

  //*********************************************************************************

  return (
    <div className="profile-page-container">
      {/* Section one */}
      <section className="profile-page-section1">
        <div className="Top-heading-and-para">
          <h2>Profile</h2>
          <p>
            User cannot change email address , if you want to change contact
            support@warrantyninja.com
          </p>
        </div>
        <hr className="horizontal-line" />

        <Dialog
          open={alert}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" color="red">
            {"You have made changes!!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Do you want to save?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Discard
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <div className="profile-card">
          {edit ? (
            <div className="profile-edit-icon" style={{ display: "flex" }}>
              <span onClick={alterEdit}>
                <DeleteIcon color="primary" />
              </span>
              <span onClick={handleUpdate}>
                <SaveIcon color="primary" />
              </span>
            </div>
          ) : (
            <span className="profile-edit-icon" onClick={alterEditTrue}>
              <EditIcon color="primary" />
            </span>
          )}

          {userDetails != null ? (
            <div>
              <div className="profile-row">
                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    First Name
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {edit ? (
                      <TextField
                        variant="outlined"
                        placeholder="First Name"
                        fullWidth
                        onChange={handleChange}
                        name="firstName"
                        className="profile-textfeild"
                        value={firstName}
                      />
                    ) : (
                      <div>
                        <strong className="light-heading-h4">
                          {firstName}
                        </strong>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Last Name
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {edit ? (
                      <TextField
                        variant="outlined"
                        placeholder="last Name"
                        fullWidth
                        onChange={handleChange}
                        name="lastName"
                        className="profile-textfeild"
                        value={lastName}
                      />
                    ) : (
                      <div>
                        <strong className="light-heading-h4">{lastName}</strong>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Dealership Name
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {edit ? (
                      <TextField
                        variant="outlined"
                        placeholder="Dealership Name"
                        fullWidth
                        onChange={handleChange}
                        name="dealershipName"
                        className="profile-textfeild"
                        value={dealershipName}
                      />
                    ) : (
                      <div>
                        <strong className="light-heading-h4">
                          {dealershipName}
                        </strong>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Dealership Address
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {edit ? (
                      <TextField
                        variant="outlined"
                        placeholder="Dealership Address"
                        fullWidth
                        onChange={handleChange}
                        name="address"
                        className="profile-textfeild"
                        value={address}
                      />
                    ) : (
                      <div>
                        <strong className="light-heading-h4">{address}</strong>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Contact Number
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {edit ? (
                      <TextField
                        variant="outlined"
                        placeholder="Contact Number"
                        fullWidth
                        onChange={handleChange}
                        name="phoneNumber"
                        className="profile-textfeild"
                        value={phoneNumber}
                        error={!isPhoneValid(phoneNumber)}
                      />
                    ) : (
                      <div>
                        <strong className="light-heading-h4">
                          {phoneNumber}
                        </strong>
                      </div>
                    )}
                    {isPhoneValid(phoneNumber) ? null : ( // phone number validity check
                      <p className="validation-error">
                        Enter valid mobile number
                      </p>
                    )}
                  </Grid>
                </Grid>
                {edit ? (
                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      Current Brands
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      {dealerBrand.map((item, index) => (
                        <div
                          className="displayed-brand"
                          key={item.dealer_brand}
                        >
                          <span>{item.dealer_brand}</span>
                          <span
                            type="button"
                            className="brand-cancel-button"
                            onClick={() => deleteBrand(item.brand_id)}
                            type="button"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete Brand"
                          >
                            &#128473;
                          </span>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                ) : null}
                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Brands
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {" "}
                    {edit ? (
                      <Select
                        isMulti
                        placeholder="Brands"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={carBrandNames}
                        onChange={(value) => {
                          let arr = [];
                          value.map((data) => {
                            arr.push(data.value);
                          });
                          setBrandUpdate(arr);
                        }}
                        name="Brand"
                      />
                    ) : (
                      <div className="saved-brands">
                        {dealerBrand.map((data) => {
                          return (
                            <strong className="light-heading-h4">
                              {data.dealer_brand + ","}
                            </strong>
                          );
                        })}
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Email Id
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    <div>
                      <strong className="light-heading-h4">{emailId}</strong>
                    </div>
                    {/* )} */}
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Alternate Email Ids
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {emailArray &&
                      emailArray.map((item) => {
                        return (
                          <div
                            className={edit ? "displayed-brand" : null}
                            key={item.dealer_email}
                          >
                            {edit ? (
                              <div>
                                <span>{item.dealer_email}</span>
                                <span
                                  type="button"
                                  className="brand-cancel-button"
                                  onClick={() => deleteEmail(item.id)}
                                  type="button"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Email"
                                >
                                  &#128473;
                                </span>
                              </div>
                            ) : (
                              <strong className="light-heading-h4">
                                {item.dealer_email}
                              </strong>
                            )}
                          </div>
                        );
                      })}
                    {edit
                      ? alternateEmails.map((data, index) => {
                          return (
                            <div className="displayed-brand">
                              <span>{data}</span>
                              <span
                                type="button"
                                className="brand-cancel-button"
                                onClick={() => deleteAlternateEmail(index)}
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete Email"
                              >
                                &#128473;
                              </span>
                            </div>
                          );
                        })
                      : null}
                  </Grid>
                </Grid>

                {edit ? (
                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      Add alternate Emails
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      <TextField
                        variant="outlined"
                        name="alternateEmailId"
                        fullWidth
                        type="email"
                        label="Alternate Email-Ids"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <i
                                className="fa fa-plus plus-icon"
                                onClick={addEmail}
                              ></i>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    State
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {edit ? (
                      <Select
                        placeholder="State"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        // styles={{option:styles => ({...styles, backgroundColor: '#01ACED',color:"white"})}}
                        options={[
                          { value: "Alabama", label: "Alabama" },
                          { value: "Alaska", label: "Alaska" },
                          { value: "Arizona", label: "Arizona" },
                          { value: "Arkansas", label: "Arkansas" },
                          { value: "California", label: "California" },
                          { value: "Colorado", label: "Colorado" },
                          { value: "Connecticut", label: "Connecticut" },
                          { value: "Delaware", label: "Delaware" },
                          { value: "Florida", label: "Florida" },
                          { value: "Georgia", label: "Georgia" },
                          { value: "Hawaii", label: "Hawaii" },
                          { value: "Illinois", label: "Illinois" },
                          { value: "Idaho", label: "Idaho" },
                          { value: "Indiana", label: "Indiana" },
                          { value: "Iowa", label: "Iowa" },
                          { value: "Kansas", label: "Kansas" },
                          { value: "Kentucky", label: "Kentucky" },
                          { value: "Louisiana", label: "Louisiana" },
                          { value: "Maine", label: "Maine" },
                          { value: "Maryland", label: "Maryland" },
                          { value: "Massachusetts", label: "Massachusetts" },
                          { value: "Michigan", label: "Michigan" },
                          { value: "Minnesota", label: "Minnesota" },
                          { value: "Mississippi", label: "Mississippi" },
                          { value: "Missouri", label: "Missouri" },
                          { value: "Montana", label: "Montana" },
                          { value: "Nebraska", label: "Nebraska" },
                          { value: "Nevada", label: "Nevada" },
                          { value: "New Hampshire", label: "New Hampshire" },
                          { value: "New Jersey", label: "New Jersey" },
                          { value: "New Mexico", label: "New Mexico" },
                          { value: "New York", label: "New York" },
                          {
                            value: "North Carolina",
                            label: "North Carolina",
                          },
                          { value: "North Dakota", label: "North Dakota" },
                          { value: "Ohio", label: "Ohio" },
                          { value: "Oklahoma", label: "Oklahoma" },
                          { value: "Oregon", label: "Oregon" },
                          { value: "Pennsylvania", label: "Pennsylvania" },
                          { value: "Rhode Island", label: "Rhode Island" },
                          {
                            value: "South Carolina",
                            label: "South Carolina",
                          },
                          { value: "South Dakota", label: "South Dakota" },
                          { value: "Tennessee", label: "Tennessee" },
                          { value: "Texas", label: "Texas" },
                          { value: "Utah", label: "Utah" },
                          { value: "Vermont", label: "Vermont" },
                          { value: "Virginia", label: "Virginia" },
                          { value: "Washington", label: "Washington" },
                          { value: "West Virginia", label: "West Virginia" },
                          { value: "Wisconsin", label: "Wisconsin" },
                          { value: "Wyoming", label: "Wyoming" },
                        ]}
                        onChange={(value) => {
                          setstate(value["value"]);
                        }}
                        name="state"
                      />
                    ) : (
                      <div>
                        <strong className="light-heading-h4">{state}</strong>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Zipcode
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    {edit ? (
                      <TextField
                        variant="outlined"
                        placeholder="Zipcode"
                        fullWidth
                        onChange={handleChange}
                        name="zipcode"
                        className="profile-textfeild"
                        value={zipcode}
                        error={!isZipcode(zipcode)}
                      />
                    ) : (
                      <div>
                        <strong className="light-heading-h4">{zipcode}</strong>
                      </div>
                    )}
                    {isZipcode(zipcode) ? null : ( // phone number validity check
                      <p className="validation-error">Enter valid zipcode</p>
                    )}
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "2%" }}>
                  <Grid sm={4} xs={12} item>
                    Country
                  </Grid>
                  <Grid sm={8} xs={12} item>
                    <div>
                      <strong className="light-heading-h4">{country}</strong>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <div>
              <Spinner1 />
            </div>
          )}
        </div>
        <hr className="horizontal-line" />
      </section>

      {/* Section second */}
      <section className="profile-page-section2">
        {userDetails != null ? (
          <div className="Profile-picture-container">
            <h4 className="profile-Picture-heading">
              {firstName} {lastName}
            </h4>
            <p className="profile-Picture-para">{dealershipName}</p>
            {imageLoader ? (
              <Spinner1 />
            ) : (
              <div className="Profile-img-container">
                {profileImgUrl === null || profileImgUrl === "" ? (
                  <div>
                    {/* <span>
                      <AccountCircleRounded size="large" />
                    </span> */}
                    <img
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                      alt="alt"
                      className="profile-section-img"
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={profileImgUrl}
                      alt="alt"
                      className="profile-section-img"
                    />
                  </div>
                )}
              </div>
            )}
            <hr />
            {editImage == false ? (
              <button
                className="profile-Picture-btn"
                onClick={() => setEditImage(true)}
              >
                Edit Image
              </button>
            ) : (
              <div>
                <input
                  className=""
                  onChange={(e) => {
                    setProfilePic(e.target.files[0]);
                    setProfilePicFlag(true);
                  }}
                  type="file"
                />
                <button className="profile-Picture-btn" onClick={saveEditImage}>
                  Save Profile
                </button>
              </div>
            )}{" "}
          </div>
        ) : (
          <Spinner1 />
        )}
      </section>
    </div>
  );
};

export default Profile;
