import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer-container">
      <footer>
        <div className="Footer-Section1">
          <div className="Footer-Section1-subSection1">
            <h3 className="subSection1-heading">Services</h3>
            <ul className="subSection-listContainer">
              <li>
                <a className="subSection-listItems" href="!">
                  Terms
                </a>
              </li>
              <li>
                <a className="subSection-listItems" href="!">
                  Help
                </a>
              </li>
              <li>
                <a className="subSection-listItems" href="!">
                  Privacy
                </a>
              </li>
            </ul>
          </div>
          <div className="Footer-Section1-subSection2">
            <h3 className="subSection2-heading">Quick Links</h3>
            <ul className="subSection-listContainer">
              <li>
                <a className="subSection-listItems" href="!">
                  Cookie policy
                </a>
              </li>
              <li>
                <a className="subSection-listItems" href="#about-us">
                  About us
                </a>
              </li>
              <li>
                <a className="subSection-listItems" href="!">
                  Contact us
                </a>
              </li>
            </ul>
          </div>

          <div className="Footer-Section1-subSection3">
            <p className="Section1-subSection3-heading">Follow Us On:</p>
            <a href="!">
              <i id="socialMedia-icon" className="social-media-icon fa fa-lg fa-facebook"></i>
            </a>
            <a href="!">
              <i className="social-media-icon fa fa-lg fa-twitter"></i>
            </a>
            <a href="!">
              <i className="social-media-icon fa fa-lg fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="Footer-Section2">
          <div className="Footer-Section2-subSection1">
            <p>© 2020 XYZ, All rights reserved</p>
          </div>
          <div className="Footer-Section2-subSection2">
            <p className="subSection-text">Designed and Developed by</p>
            <a href="https://scrobits.com">Scrobits Technologies</a>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
