import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { Component } from "react";
import { Spinner1 } from "../../../Spinners";
import { Redirect } from "react-router-dom";
import { IP } from "../../../ip/IPConfig";
import "./Quotes.css";
import moment from "moment";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from "draft-js";

class Quotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // quote details are stored in this state
      quoteDetails: null,

      // bid details are stored here
      bidDetails: [],

      // edit flag
      edit: false,

      // dealer message state
      dealer_msg: "",

      // pop up flag to display price of quote popup
      chargePopup: false,

      //
      validation: false,

      // car price array
      carPrice: [],
      approve: false,
      //variables below are used for dropdown
      quoteClick: 0,
      carQuoteDetails: [],
      allBids: [],
      clickedQuoteObj: {},

      // variable to store dealer msg style from text editor
      dealerMsgStyle: "",
    };
  }

  // called on change from the text editor
  save = (data) => {
    data = JSON.parse(data);
    console.log(
      data,
      data.blocks
        .map((item) => {
          return item.text;
        })
        .toString()
    );

    this.setState({
      // store the dealer msg in text format
      dealer_msg: data.blocks
        .map((item) => {
          return item.text;
        })
        .toString(),

      // dealer msg styling is stored here
      dealerMsgStyle: data.blocks.map((item) => {
        return item.inlineStyleRanges;
      }),
    });
  };
  // **************************************fetching for dropdown************************************

  // fetching bid details takes argument quote_id
  async dropdownBidDetails(id) {
    this.setState({ bidDetails: [], dealer_msg: "" });
    await fetch(`${IP}/admin/quotes/${id}`, {
      headers: new Headers({
        // authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        console.log(" Car quotes data ", response);
        this.setState({
          quoteDetails: response, // response is stored in quote details array
        });
        this.fetchBidDetails(id); // call to fetch bid details this takes quote id as argument
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });

    this.props.match.params.id = id;
  }
  // ***********************************************************************************************

  // fetch all the quotes of a dealer.
  async fetchAllQuotesByDealerId() {
    await fetch(`${IP}/dealer/quotes/${sessionStorage.getItem("dealer_id")}`, {
      headers: new Headers({
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        this.setState({
          quoteDetails: response, // store the quotes in quote details array
        });
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  }

  async componentDidMount() {
    // async function fetchCarQuotes() {
    //   console.log(`authorization Bearer ${sessionStorage.getItem('token')} url: ${IP}/dealer/bid/list/1`);
    //   await

    // fetch all dealer quotes
    if (sessionStorage.getItem("approved") === "pending") {
      this.setState({
        approve: true,
      });
    }
    if (sessionStorage.getItem("approved") !== "pending") {
      await fetch(
        `${IP}/dealer/quotes/${sessionStorage.getItem("dealer_id")}`,
        {
          headers: new Headers({
            authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
          }),
        }
      )
        .then(async (res) => {
          const response = await res.json();
          this.setState({ carQuoteDetails: response }); // set quotes in the car quotes array
        })
        .catch((err) => {
          console.log("error in customer quote ", err);
        });

      // if status is pending then set approve to true so that user is directed towards waiting page
      if (this.props.match.params.id === "1") {
        await this.fetchAllQuotesByDealerId();
        if (this.state.quoteDetails.length > 0) {
          this.fetchBidDetails(this.state.quoteDetails[0]["quote_id"]);
        }
      } else {
        // fetching quote details
        await fetch(`${IP}/admin/quotes/${this.props.match.params.id}`, {
          headers: new Headers({
            authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
          }),
        })
          .then(async (res) => {
            const response = await res.json();
            this.setState({
              quoteDetails: response,
            });
            this.fetchBidDetails(this.props.match.params.id);
          })
          .catch((err) => {
            console.log("error in customer quote ", err);
          });
      }
    }
  }
  // fetching bid details function takes quote id as argument
  async fetchBidDetails(id) {
    await fetch(
      `${IP}/dealer/bid/${sessionStorage.getItem("dealer_id")}/${id}`,
      {
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        if (response.length === 0) {
          this.setState({
            edit: true,
          });
        } else {
          this.setState({
            // bid details and bid message are stored in states
            bidDetails: response,
            dealer_msg: response[0]["bids_message"],
          });
        }
      })
      .catch((err) => {
        console.log("error in dealer quote ", err);
      });
  }

  // fetching car price
  // this takes 2 arguments car maker and car model
  fetchCarPrice(carMaker, carModel) {
    // fetch the corresponding car price to display in the popup
    fetch(`${IP}/dealer/car/price/${carMaker}/${carModel}`, {
      headers: new Headers({
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        this.setState({
          carPrice: response,
        });
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  }

  // called when we click the edit button
  handleEdit = (e) => {
    e.preventDefault();
    this.setState({
      edit: true,
    });
  };

  //
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // called when we cancel the editing of quote
  handleCancel = (e) => {
    e.preventDefault();
    this.setState({
      edit: false,
    });
    this.fetchBidDetails(
      this.props.match.params.id === "1"
        ? this.state.quoteDetails[0]["quote_id"]
        : this.props.match.params.id
    );
  };

  // handle save is called when we save a quote
  handleSave = (e) => {
    e.preventDefault();
    if (this.state.dealer_msg.length === 0) {
      this.setState({
        validation: true,
      });
    } else {
      if (this.state.bidDetails.length === 0) {
        // check bid details length
        if (this.state.quoteDetails.length > 0) {
          // check quote details length
          // fetching car price
          this.fetchCarPrice(
            this.state.quoteDetails[0]["car_maker"],
            this.state.quoteDetails[0]["car_model"]
          );
        }
        // show charge popup for confirmation
        this.setState({
          chargePopup: true,
          validation: false,
        });
      } else {
        // patch the edited bid to the server
        fetch(`${IP}/dealer/bid/edit/${sessionStorage.getItem("dealer_id")}`, {
          method: "PATCH",
          headers: new Headers({
            authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            message: this.state.dealer_msg,
            quote_id: this.props.match.params.id,
          }),
        })
          .then(async (res) => {
            // set the edit and alert to false
            this.setState({
              edit: false,
              alert: false,
            });
          })
          .catch((err) => {
            console.log("err in update ", err);
          });
      }
    }
  };

  // called when we close the popup
  handleClosePopup = (e) => {
    e.preventDefault();
    this.setState({
      chargePopup: false,
    });
  };

  // function is called when we add the bid
  handleAddbid = (e) => {
    e.preventDefault();
    console.log(
      this.state.dealer_msg,
      this.state.carPrice[0]["price"],
      this.props.match.params.id === "1"
        ? this.state.quoteDetails[0].quote_id
        : this.props.match.params.id
    );
    // POST the bid to the server
    fetch(`${IP}/dealer/bid/add/${sessionStorage.getItem("dealer_id")}`, {
      method: "POST",
      headers: new Headers({
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        message: this.state.dealer_msg,
        quoteId:
          this.props.match.params.id === "1"
            ? this.state.quoteDetails[0].quote_id
            : this.props.match.params.id,
        amount: this.state.carPrice[0]["price"],
      }),
    })
      .then(async (res) => {
        const response = await res;
        this.setState({
          chargePopup: false,
        });
        // window.location.reload();
        this.fetchAllQuotesByDealerId();
        if (this.state.quoteDetails.length > 0) {
          this.fetchBidDetails(this.state.quoteDetails[0]["quote_id"]);
        }
        console.log("response : ", response);
      })
      .catch((err) => {
        console.log("err in update ", err);
      });
  };

  // text editor onchange saves current data of the editor as dealer msg
  onChange = (event) => {
    const plainText = event.getCurrentContent().getPlainText(); // for plain text
    const rteContent = convertToRaw(event.getCurrentContent()); // for rte content with text formating

    this.setState({
      dealer_msg: rteContent.blocks
        .map((item) => {
          return item.text;
        })
        .toString(),
      dealerMsgStyle: rteContent.blocks.map((item) => {
        return item.inlineStyleRanges;
      }),
    });
  };

  render() {
    console.log(this.state.bidDetails);

    // if not approved dealer is redirected to the waiting page
    if (this.state.approve) {
      return (
        <div>
          <Redirect to="/dealer/waiting" />
        </div>
      );
    }
    return (
      <div>
        <Dialog
          onClose={this.handleClosePopup}
          aria-labelledby="simple-dialog-title"
          open={this.state.chargePopup}
        >
          <DialogTitle id="simple-dialog-title">One Step closer </DialogTitle>
          <DialogContent>
            {this.state.carPrice.length > 0 ? (
              <div>
                For {this.state.carPrice[0]["car_maker"]}{" "}
                {this.state.carPrice[0]["car_model"]} Charge of $
                {this.state.carPrice[0]["price"]} will be included in the
                invoice.
              </div>
            ) : (
              <div>
                <Spinner1 />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "15%",
              }}
            >
              <Button
                className="discard-button"
                onClick={this.handleClosePopup}
              >
                Decline
              </Button>
              <Button className="confirm-button" onClick={this.handleAddbid}>
                Confirm
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <div className="all-quotes-drop-down">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item  quotes-drop-down">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  All Quotes
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse open"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body accordian-dropdown-options">
                  {this.state.carQuoteDetails &&
                    this.state.carQuoteDetails.map((data) => {
                      return (
                        <div
                          className="dropdown-layout"
                          onClick={() => this.dropdownBidDetails(data.quote_id)}
                        >
                          <div className="drop-down-column-1">
                            Car Model : {data.car_maker}- {data.car_model}
                          </div>
                          <div className="drop-down-column-1">
                            Quote ID : {data.quote_id}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.quoteClick == 0 ? ( */}
        <div className="dealer-quotes-card">
          <h1>Quote</h1>
          {this.state.quoteDetails !== null ? (
            this.state.quoteDetails.length > 0 ? (
              <div>
                <div className="dealer-quote-top2">
                  <div className="dealer-quote-top">
                    {/* <div>{this.state.quoteDetails[0]["timestamp"]} </div> */}
                    <div>
                      {moment(
                        this.state.quoteDetails[0]["timestamp"]
                      ).fromNow()}
                    </div>
                    <p>
                      {this.state.quoteDetails[0]["car_maker"]}{" "}
                      {this.state.quoteDetails[0]["car_model"]}{" "}
                      {this.state.quoteDetails[0]["car_mileage"]}
                    </p>
                  </div>
                  {this.state.dealer_msg == "" ? (
                    <div className="customer-detail-alert">
                      <p>
                        Personal details will be visible once the bid is
                        submitted{" "}
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                {this.state.bidDetails.length === 0 ||
                this.state.quoteDetails === 0 ? (
                  <div className="quote-customer-detail">
                    <p>
                      <span>Customer Name</span> : XXXXX XXXXX
                    </p>
                    <p>
                      <span>Customer Number</span> : XXXXX XXXXX
                    </p>
                    <p>
                      <span>Email</span> : XXXXXXXXXXXXXXXXXXXX
                    </p>
                    <small>Please Enter/Edit the quote</small>
                    <p>
                      Message :{" "}
                      {this.state.quoteDetails[0]["customer_comments"]}
                    </p>
                    {this.state.bidDetails.length > 0 ? (
                      <p>Price : {this.state.bidDetails[0]["amount"]}</p>
                    ) : null}
                  </div>
                ) : (
                  <div className="quote-customer-detail">
                    <p>
                      <span>Customer Name :</span>{" "}
                      {this.state.quoteDetails[0]["user_firstname"]}{" "}
                      {this.state.quoteDetails[0]["user_lastname"]}
                    </p>
                    <p>
                      <span>Customer Number : </span>
                      {this.state.quoteDetails[0]["user_phn"]}
                    </p>
                    <p>
                      <span>Email : </span>
                      {this.state.quoteDetails[0]["user_email"]}
                    </p>
                    <small>Please Enter/Edit the quote</small>
                    <p>
                      <span>Message :</span>{" "}
                      {this.state.quoteDetails[0]["customer_comments"]}
                    </p>
                    {this.state.bidDetails.length > 0 ? (
                      <p>
                        <span>Price :</span>{" "}
                        {"$" + this.state.bidDetails[0]["amount"]}
                      </p>
                    ) : null}
                  </div>
                )}
                <div className="quote-actual-details">
                  {this.state.edit ? (
                    <div>
                      <MUIRichTextEditor // material ui rich text editor refrence :- https://www.npmjs.com/package/mui-rte
                        label="Type something here..."
                        onSave={this.save}
                        inlineToolbar={true}
                        onChange={this.onChange}
                        controls={[
                          "title",
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "highlight",
                          "undo",
                          "redo",
                          "numberList",
                          "bulletList",
                        ]}
                        // defaultValue={JSON.stringify(this.state.bidDetails[0]['bids_message'])}
                      />
                    </div>
                  ) : this.state.dealer_msg.indexOf(",") != -1 ? (
                    <p>
                      {" "}
                      {this.state.dealer_msg.split(",").map((item, index) => {
                        return (
                          <p
                            style={{
                              fontWeight: this.state.dealerMsgStyle
                                ? this.state.dealerMsgStyle[
                                    index
                                  ].style.toLowerCase()
                                : "500",
                            }}
                          >
                            {item}
                          </p>
                        );
                      })}
                    </p>
                  ) : (
                    <p>{this.state.dealer_msg}</p>
                  )}
                </div>
                <div className="quotes-button-div">
                  {this.state.edit ? ( // if edit state is true show cancel and submit button else show edit button
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {this.state.bidDetails.length === 0 ? (
                        <div></div>
                      ) : (
                        <Button
                          className="cancel-button"
                          onClick={this.handleCancel}
                        >
                          Cancel
                        </Button>
                      )}
                      <Button className="save-button" onClick={this.handleSave}>
                        Submit
                      </Button>
                    </div>
                  ) : (
                    <Button className="edit-button" onClick={this.handleEdit}>
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <center>
                  <h4>No Quotes Found</h4>
                </center>
              </div>
            )
          ) : (
            <div>
              <Spinner1 />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Quotes;
