const initialState = {
  loginData: {},
  userData: {},
};

export const formSubmitReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SubmitForm":
      state.loginData = action.payload  
    return {
        ...state,
      };

    case "dealerSignup":
      state.loginData = action.payload  
      return {
          ...state,
        };

    case "customerLogin":
      state.loginData = action.payload  
    return {
        ...state,
      };

    case "customerLoginData":
      state.userData = action.payload  
      return {
          ...state,
        };

    default:
      return state;
  }
};


// export const dealerSignupReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "dSignup":
//       let data = action.payload;
//       state.userData = { ...state.userData, ...data };

//     default:
//       return state;
//   }
// };
