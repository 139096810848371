export const submitRegisterationForm = (data) => {

    return{
        type:"SubmitForm",
        payload: data
    }
}
// customer actions
export const customerLogin = (data) => {
    
    return{
        type:"customerLogin",
        payload: data
    }
}

export const customerLoginData = (data) => {
    
    return{
        type:"customerLoginData",
        payload: data
    }
}
// Dealer actions

export const dealerSignup = (data) => {

    return{
        type:"dealerSignup",
        payload: data
    }
}