import React from "react";
import './Spinners.css';

export function Spinner1(props) {
  return (
    <div>
      <div className="spinner">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    </div>
  );
}

export function Spinner2(props) {
  return (
    <div>
      <div className="spinner2"></div>
    </div>
  );
}
