import React, { Component } from "react";
import hero1 from "../images/hero.webp";
import services5 from "../images/service5.webp";
import services2 from "../images/service2.webp";
import services1 from "../images/service1.webp";
import jumboimg from "../images/jumboimg.webp";
import services4 from "../images/service4.webp";
import services6 from "../images/service6.webp";
import "./LandingPage.css";
import "./styles.css";
import RegistrationForm from "./RegistrationForm";

export class LandingPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    // whenever the page loads it is scrolled back to top
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  render() {
    return (
      <div>
        <section>
          <div id="header" className="hero-section">
            <div className="hero-text-section">
              <h1>
                <span className="strikethrough">OVERPAYER</span>
              </h1>
              <h5>
                <strong className="sub-title"> Your Negotiator!</strong>
              </h5>
              <h2 className="my-3">
                Empowering you with the best manufacturer car quotes in real
                time
              </h2>
            </div>
            <div className="hero-img-section img-fluid">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fhero.webp?alt=media&token=597b5fe9-4522-44e0-9b4d-c22deeb9509f"
                alt="hero page"
                className="hero-img"
              />
            </div>
          </div>
          <div className="hero-btn">
            <a href="#registeration-form" className="btn-get-started">
              Get Started
            </a>
          </div>
        </section>
        <section id="about-us">
          <div className="section2-card-even">
            <div className="section2-card-img">
              <img className="section2-img" src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fservice5.webp?alt=media&token=00267cb7-4f9f-45c2-b601-8d7b0cec6c59" />
            </div>
            <div className="section2-card-text">
              <h2>Buying a new or used car?</h2>
              <p>
                Let us know before you step into the dealership! Our network of
                online dealers can not only provide you with best new car prices
                but also an extensive inventory of well maintained used cars at
                no cost
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="section2-card-odd">
            <div className="section2-card-text">
              <h2>No third party warranties!</h2>
              <p>
                We provide you with the best in class extended manufacturer
                warranty for your car.
              </p>
            </div>
            <div className="section2-card-img">
              <img className="section2-img section-2-img" src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fservice2.webp?alt=media&token=df7cad80-f2cc-4697-9c96-333534f2" />
            </div>
          </div>
        </section>
        <section>
          <div className="section2-card-even">
            <div className="section2-card-img">
              <img className="section2-img section-2-img" src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fservice5.webp?alt=media&token=00267cb7-4f9f-45c2-b601-8d7b0cec6c59" />
            </div>
            <div className="section2-card-text">
              <h2>Don't overpay for warranty!</h2>
              <p>
                Our network of dealers provide you with multiple quotes and
                plans at no cost.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="section2-card-odd">
            <div className="section2-card-text">
              <h2>Real time quotes!</h2>
              <p>
                {" "}
                As soon as you submit the vehicle details, dealers start working
                on it to provide you the best quotes.
              </p>
            </div>
            <div className="section2-card-img">
              <img className="section2-img section-2-img" src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fservice4.webp?alt=media&token=6e4c27d2-e5f5-464a-a217-a9189af6c14e" />
            </div>
          </div>
        </section>
        <section>
          {/* <div className="section2-card-even">
            <div className="section2-card-img">
              <img className="section2-img section-2-img" src={services5} />
            </div>
            <div className="section2-card-text">
              <h2>Dealer Vs Dealer</h2>
              <p>
                Dealers bid for your business. Getting you the best deals with
                no hassle.
              </p>
            </div>
          </div> */}
        </section>
        <section>
          <div className="section2-card-odd">
            <div className="section2-card-text">
              <h2>Save more with us!</h2>
              <p>
                {" "}
                On an average you will save around $400 on the vehicle warranty
                with Warranty Ninja.
              </p>
            </div>
            <div className="section2-card-img">
              <img className="section2-img section-2-img" src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fservice6.webp?alt=media&token=befaf580-3665-4f8a-a7de-81e1cfb5fba2" />
            </div>
          </div>
        </section>
        <section>
          <div className="section3-card">
            <div className="section3-card-text">
              <h2>12000+ satisfied customers</h2>
              <p>Serving 100+ customers daily!</p>
            </div>
            <div className="section3-card-img">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fjumboimg.webp?alt=media&token=9cf41b70-71b6-4149-a6ae-f7141b4e6b8d"
                className="section3-img"
                alt="loading image"
              />
            </div>
          </div>
        </section>
        {sessionStorage.getItem("Login") ? (
          <div></div>
        ) : (
          <section>
            <div className="section4-card" id="registeration-form">
              <h1> Easy as 1, 2, 3 !</h1>
              <h1> IT'S COMPLETELY FREE</h1>
              <p>
                Just fill in the details below to receive the best manufacturer
                warranty quotes on your new/used car
              </p>
            </div>
            {/* Registeration form component is rendered here */}
            <RegistrationForm />
          </section>
        )}
        <section className="section5-card">
          <img src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fservice1.webp?alt=media&token=f90d9e81-f167-44ff-a2d1-351f2112a4d2" alt="loading image" />
        </section>
      </div>
    );
  }
}

export default LandingPage;
