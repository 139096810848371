import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import { IP } from "../../../ip/IPConfig";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Done";
import "./CustomerProfilePage.css";
import { Spinner1, Spinner2 } from "../../../Spinners";
import { connect } from "react-redux";
import { customerLogin, customerLoginData } from "../../../../redux/actions";
import { isPhoneValid, isZipcode } from "../../../ValidationFunctions";
import { firebasecode } from "../../../../firebase/firebase";
import Select from "react-select";

const mapStateToProps = (state) => {
  return {
    loginState: state.formSubmit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLoginData: (data) => {
      dispatch(customerLogin(data));
    },
    afterLoginData: (data) => {
      dispatch(customerLoginData(data));
    },
  };
};

class CustomerProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // customer information is stored in these states
      firstName: "",
      lastName: "",
      address: "",
      phoneNumber: "",
      userName: "",
      state: "",
      zipcode: "",
      emailId: "",
      country: "",

      // all user details are stored in this array
      userDetails: [],

      // profile edit button state
      edit: false,

      // profile pic edit button state
      editImage: false,
      change: 0,
      alert: false,

      // profile image url is stored in this state
      profileImageUrl: null,

      // loader state to show loader until profile pic loads
      loader: false,

      storage: null,

      // validity check states
      zipcodeValidityCheck: true,
      phoneValidityCheck: true,
    };
  }
  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  async componentDidMount() {
    this.state.storage = firebasecode.storage(); // access to firebase storage

    window.scrollTo({ top: 0 }); // scrolls to top after page render

    // fetching customer profile from server
    await fetch(
      `${IP}/customer/profile/${sessionStorage.getItem("customerId")}`,
      {
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`, // token is passed to the server for verification
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        console.log(response,sessionStorage.getItem("idToken"))
        this.setState({
          userDetails: response, // whole response is stored in the userDetails array

          // values are stored in the respective states
          firstName: response[0]["user_firstname"],
          lastName: response[0]["user_lastname"],
          address: response[0]["user_address"],
          phoneNumber: response[0]["user_phn"],
          userName: response[0]["user_email"].split("@")[0],
          state: response[0]["user_state"],
          zipcode: response[0]["user_pincode"],
          emailId: response[0]["user_email"],
          country: response[0]["user_country"],
          profileImageUrl: response[0]["user_image_url"],
        });
        console.log("user data ", this.state.userDetails);
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  }

  // this function is called when we click the profile edit close button
  handleClose = () => {
    this.setState({
      firstName: this.state.userDetails[0]["user_firstname"],
      lastName: this.state.userDetails[0]["user_lastname"],
      address: this.state.userDetails[0]["user_address"],
      phoneNumber: this.state.userDetails[0]["user_phn"],
      userName: this.state.userDetails[0]["user_name"],
      state: this.state.userDetails[0]["user_state"],
      zipcode: this.state.userDetails[0]["user_pincode"],
      emailId: this.state.userDetails[0]["user_email"],
      alert: false,
      edit: false,
      profileImageUrl: this.state.profileImageUrl,
    });
  };

  // handle change function is used to store the edited values in their respective states
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      change: 1,
    });
  };

  // this function is called when we click the profile edit cancel button
  alterEdit = (e) => {
    // if the values are changed i.e change=1
    if (this.state.change !== 0) {
      this.setState({
        alert: true,
      });
    } else {
      this.setState({
        edit: false,
      });
    }
  };

  // this function is called when we click the profile edit icon
  alterEditTrue = (e) => {
    this.setState({
      edit: true,
    });
  };

  // this function is called when we change the profile picture our uploaded profile picture is uploaded to firebase storage and then image url is taken from the storage and stored in the profileImageUrl
  imageHandler = (img) => {
    console.log("in image handler");
    this.setState({
      loader: true,
    });
    var storageRef = this.state.storage.ref();
    var spaceRef = storageRef.child(`images/${img["name"]}`);
    var url;
    spaceRef
      .put(img)
      .then(async (res) => {
        url = await spaceRef.getDownloadURL();
        console.log(url);
        this.setState({
          profileImageUrl: url,
          loader: false,
        });
      })
      .catch((err) => {
        console.log("error while uploading", err);
      });
  };

  //called when we save the edited profile it updates the new values to the server
  handleUpdate = (e) => {
    // e.preventDefault();

    console.log("in Handle Update");

    if (
      this.state.phoneNumber.trim() !== "" &&
      this.state.zipcode.trim() !== "" &&
      this.state.firstName.trim() !== "" &&
      this.state.lastName.trim() !== "" &&
      this.state.phoneNumber.trim() !== "" &&
      this.state.emailId.trim() !== "" &&
      this.state.state.trim() !== "" && 
      isPhoneValid(this.state.phoneNumber) &&
      isZipcode(this.state.zipcode)
    ) {
      // checks the phone number validity
      fetch(
        `${IP}/customer/profile/update/${sessionStorage.getItem(
          "customer_id"
        )}`,
        {
          method: "PATCH",
          headers: new Headers({
            authorization: `Bearer ${sessionStorage.getItem("idToken")}`, // need to pass the token to the server for verification
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_firstname: this.state.firstName,
            user_lastname: this.state.lastName,
            user_name: this.state.userName,
            user_email: this.state.emailId,
            user_phn: this.state.phoneNumber,
            user_state: this.state.state,
            user_pincode: this.state.zipcode,
            user_image_url: this.state.profileImageUrl,
          }),
        }
      )
        .then(async (res) => {
          const response = await res.json();
          this.setState({
            // making all the control states false
            edit: false,
            alert: false,
            loader: false,
          });
          sessionStorage.setItem("user_phone", this.state.phoneNumber)
          console.log("response : ", response);
          sessionStorage.setItem("user_name", this.state.firstName);
        })
        .catch((err) => {
          console.log("err in update ", err);
        });
    } else {
      alert("Enter proper values");
    }
  };
  render() {
    if (isZipcode(this.state.zipcode)) {
      this.state.zipcodeValidityCheck = true;
    } else {
      this.state.zipcodeValidityCheck = false;
    }

    return (
      <div className="customer-profile-page-container">
        <div className="profile-card">
          <Dialog
            open={this.state.alert}
            TransitionComponent={this.Transition}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title" color="red">
              {"You have made changes!!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Do you want to save?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Discard
              </Button>
              <Button onClick={this.handleUpdate} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <h2 className="light-heading-h3">Profile</h2>

          <div>
            {this.state.edit ? (
              <div className="profile-edit-icon" style={{ display: "flex" }}>
                <span onClick={this.alterEdit}>
                  <DeleteIcon color="primary" />
                </span>
                <span onClick={this.handleUpdate}>
                  <SaveIcon color="primary" />
                </span>
              </div>
            ) : (
              <span className="profile-edit-icon" onClick={this.alterEditTrue}>
                <EditIcon color="primary" />
              </span>
            )}

            {this.state.userDetails.length > 0 ? (
              <div>
                <div className="profile-row">
                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      First Name
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      {this.state.edit ? (
                        <TextField
                          variant="outlined"
                          placeholder="First Name"
                          fullWidth
                          onChange={this.handleChange}
                          name="firstName"
                          className="profile-textfeild"
                          value={this.state.firstName}
                        />
                      ) : (
                        <div>
                          <strong className="light-heading-h4">
                            {this.state.firstName}
                          </strong>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      Last Name
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      {this.state.edit ? (
                        <TextField
                          variant="outlined"
                          placeholder="last Name"
                          fullWidth
                          onChange={this.handleChange}
                          name="lastName"
                          className="profile-textfeild"
                          value={this.state.lastName}
                        />
                      ) : (
                        <div>
                          <strong className="light-heading-h4">
                            {this.state.lastName}
                          </strong>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      Contact Number
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      {this.state.edit ? (
                        <TextField
                          variant="outlined"
                          placeholder="Contact Number"
                          fullWidth
                          onChange={this.handleChange}
                          name="phoneNumber"
                          className="profile-textfeild"
                          value={this.state.phoneNumber}
                          type="number"
                          error={!isPhoneValid(this.state.phoneNumber)}
                        />
                      ) : (
                        <div>
                          <strong className="light-heading-h4">
                            {this.state.phoneNumber}
                          </strong>
                        </div>
                      )}
                      {isPhoneValid(this.state.phoneNumber) ? null : ( // phone number validity check
                        <p className="validation-error">
                          Enter valid mobile number
                        </p>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      Email Id
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      <div>
                        <strong className="light-heading-h4">
                          {this.state.emailId.length > 1
                            ? this.state.emailId
                            : this.state.userName + "@gmail.com"}
                        </strong>
                      </div>
                      {/* )} */}
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      State
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      {this.state.edit ? (
                        <Select
                          placeholder="State"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          // styles={{option:styles => ({...styles, backgroundColor: '#01ACED',color:"white"})}}
                          options={[
                            { value: "Alabama", label: "Alabama" },
                            { value: "Alaska", label: "Alaska" },
                            { value: "Arizona", label: "Arizona" },
                            { value: "Arkansas", label: "Arkansas" },
                            { value: "California", label: "California" },
                            { value: "Colorado", label: "Colorado" },
                            { value: "Connecticut", label: "Connecticut" },
                            { value: "Delaware", label: "Delaware" },
                            { value: "Florida", label: "Florida" },
                            { value: "Georgia", label: "Georgia" },
                            { value: "Hawaii", label: "Hawaii" },
                            { value: "Illinois", label: "Illinois" },
                            { value: "Idaho", label: "Idaho" },
                            { value: "Indiana", label: "Indiana" },
                            { value: "Iowa", label: "Iowa" },
                            { value: "Kansas", label: "Kansas" },
                            { value: "Kentucky", label: "Kentucky" },
                            { value: "Louisiana", label: "Louisiana" },
                            { value: "Maine", label: "Maine" },
                            { value: "Maryland", label: "Maryland" },
                            { value: "Massachusetts", label: "Massachusetts" },
                            { value: "Michigan", label: "Michigan" },
                            { value: "Minnesota", label: "Minnesota" },
                            { value: "Mississippi", label: "Mississippi" },
                            { value: "Missouri", label: "Missouri" },
                            { value: "Montana", label: "Montana" },
                            { value: "Nebraska", label: "Nebraska" },
                            { value: "Nevada", label: "Nevada" },
                            { value: "New Hampshire", label: "New Hampshire" },
                            { value: "New Jersey", label: "New Jersey" },
                            { value: "New Mexico", label: "New Mexico" },
                            { value: "New York", label: "New York" },
                            {
                              value: "North Carolina",
                              label: "North Carolina",
                            },
                            { value: "North Dakota", label: "North Dakota" },
                            { value: "Ohio", label: "Ohio" },
                            { value: "Oklahoma", label: "Oklahoma" },
                            { value: "Oregon", label: "Oregon" },
                            { value: "Pennsylvania", label: "Pennsylvania" },
                            { value: "Rhode Island", label: "Rhode Island" },
                            {
                              value: "South Carolina",
                              label: "South Carolina",
                            },
                            { value: "South Dakota", label: "South Dakota" },
                            { value: "Tennessee", label: "Tennessee" },
                            { value: "Texas", label: "Texas" },
                            { value: "Utah", label: "Utah" },
                            { value: "Vermont", label: "Vermont" },
                            { value: "Virginia", label: "Virginia" },
                            { value: "Washington", label: "Washington" },
                            { value: "West Virginia", label: "West Virginia" },
                            { value: "Wisconsin", label: "Wisconsin" },
                            { value: "Wyoming", label: "Wyoming" },
                          ]}
                          onChange={(value) => {
                            this.setState({ state: value["value"] });
                          }}
                          name="state"
                        />
                      ) : (
                        <div>
                          <strong className="light-heading-h4">
                            {this.state.state}
                          </strong>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginBottom: "2%" }}>
                    <Grid sm={4} xs={12} item>
                      Zipcode
                    </Grid>
                    <Grid sm={8} xs={12} item>
                      {this.state.edit ? (
                        <TextField
                          variant="outlined"
                          placeholder="Zipcode"
                          fullWidth
                          onChange={this.handleChange}
                          name="zipcode"
                          className="profile-textfeild"
                          value={this.state.zipcode}
                          type="number"
                          error={!this.state.zipcodeValidityCheck}
                        />
                      ) : (
                        <div>
                          <strong className="light-heading-h4">
                            {this.state.zipcode}
                          </strong>
                        </div>
                      )}
                      {!this.state.zipcodeValidityCheck ? (
                        <p className="validation-error">Enter valid zipcode</p>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : (
              <Spinner1 />
            )}
          </div>
        </div>

        {/* Section second */}
        <section className="profile-page-section">
          <div className="customer-Profile-picture-container">
            <h4 className="customer-profile-Picture-heading">
              {this.state.firstName} {this.state.lastName}
            </h4>
            <div className="customer-Profile-img-container">
              {!this.state.loader ? ( // conditional rendering to show the spinner  until the image loads
                this.state.profileImageUrl !== null ? (
                  <img
                    className="profile-pic"
                    src={this.state.profileImageUrl}
                  />
                ) : (
                  <img // default no profile pictureimage
                    className="profile-pic-default"
                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  />
                )
              ) : (
                <Spinner2 />
              )}
            </div>
            <hr />
            {this.state.editImage == true ? (
              <input
                className="choose-img"
                onChange={(e) => {
                  this.imageHandler(e.target.files[0]);
                }}
                type="file"
              />
            ) : null}
            {this.state.editImage == false ? (
              <button
                className="customer-profile-Picture-btn"
                onClick={() => this.setState({ editImage: true })}
              >
                Edit Profile
              </button>
            ) : (
              <button
                className="customer-profile-Picture-btn"
                onClick={() =>
                  this.setState({ editImage: false }, this.handleUpdate)
                }
              >
                Save Profile
              </button>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerProfilePage);
// export default CustomerProfilePage;
