import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { DealerMenuList } from "./DealerMenuList";
import { CustomerMenuList } from "./CustomerMenuList";
import { LandinPageNav } from "./LandinPageNavBar";
import "./Navbar.css";
import logo from "../images/WarrantyNinjaLogo.webp";

const Navbar = () => {
  // state used to check the click on the navbar items
  const [clicked, setClicked] = useState(false);

  // variable used to store
  let menuList;

  // function used to set click state to true or false
  const handleClick = () => {
    setClicked(!clicked);
  };

  // if the login role is dealer then dealer menu list is rendered in navbar
  if (sessionStorage.getItem("role") === "Dealer") {
    menuList = DealerMenuList.map(({ url, title }, index) => {
      return (
        <li key={index} onClick={handleClick}>
          <NavLink exact to={url} activeClassName="active">
            {title}
          </NavLink>
        </li>
      );
    });

    // if the login role is customer then customer menu list is rendered in navbar
  } else if (sessionStorage.getItem("role") === "Customer") {
    menuList = CustomerMenuList.map(({ url, title }, index) => {
      return (
        <li key={index} onClick={handleClick}>
          <NavLink exact to={url} activeClassName="active">
            {title}
          </NavLink>
        </li>
      );
    });

    // if the login role is not defined i.e landing page then landing page menu list is rendered in navbar
  } else {
    menuList = LandinPageNav.map(({ url, title }, index) => {
      return (
        <li key={index} onClick={handleClick}>
          <NavLink exact to={url} activeClassName="active">
            {title}
          </NavLink>
        </li>
      );
    });
  }

  return (
    <nav className="header-nav">
      <a href="/" style={{ textDecoration: "none" }}>
        <div className="logo">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2FWarrantyNinjaLogo.webp?alt=media&token=977aca6d-dc70-417e-b14a-3ff4eac32241"
            alt="logo"
            className="logo-img"
          />
          {/* <div className="logo">WarrantyNinja</div> */}
        </div>
      </a>
      <div className="menu-icon" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul
        className={
          clicked
            ? "menu-list +animate__animated"
            : "menu-list close animate__animated"
        }
      >
        {menuList}
      </ul>
    </nav>
  );
};

export default Navbar;
