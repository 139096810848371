// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyC4LN3C9hvenxiyHNJ9dYpnguYDgzIEBw0",
    authDomain: "warrantyninja.com",
    projectId: "warrantyninja-4a6e2",
    storageBucket: "warrantyninja-4a6e2.appspot.com",
    messagingSenderId: "425145342158",
    appId: "1:425145342158:web:93cb3df4d1088ed58d589f",
    measurementId: "G-Q09GY34Y8J"
};

export const firebasecode = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();