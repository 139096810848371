import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import { IP } from "../../../ip/IPConfig";
import PayPal2 from "./PayPal2";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import "./PayPal2.css";
import { Button } from "@material-ui/core";
import { Spinner1 } from "../../../Spinners";

// table styles using useStyles refer :- https://mui.com/api/table/ for more information
const useStyles = makeStyles({
  sel: {
    marginLeft: 400,
  },
  head1: {
    marginLeft: 400,
  },
  sel2: {
    marginLeft: 10,
  },
  // table: {
  //   minWidth: 650,
  // },
});

const Invoice = () => {
  // classes are assigned the useStyles and used in the props of the table
  const classes = useStyles();

  // car invoice is stored in this state
  const [carinvoice, setcarinvoice] = useState(null);

  // month and amount to be paid of that month are stored in this states
  let [month, setMonth] = useState(null);
  let [amount, setAmount] = useState(null);

  // pop up of payable amount is shown by this flag
  let [popup, setPopup] = useState(false);

  // pdf link of invoice is stored in this state
  const [pdfLink, setPdfLink] = useState("");

  // approved flag of dealer shows if dealer is aproved by admin or not
  const [approve, setApprove] = useState(false);

  const [yearArray,setYearArray] = useState([]);

  // runs whenever the component renders
  useEffect(() => {
    // set approved true if we get value as pending
    if (sessionStorage.getItem("approved") === "pending") {
      setApprove(true);
    }

    fetchInvoiceDetails(new Date().getFullYear());

    let year = (new Date().getFullYear()) - 2010;

    for (let index = 0; index <= year; index++) {
      // from 0 to end we have for loop which stores value in the year array

      yearArray[index] = {
        value: (2010 + index).toString(),
        label: (2010 + index).toString(),
      };
    }

    yearArray.reverse();

  }, []);
  
  const fetchInvoiceDetails = (year) => {
    // fetching the invoice details from server by dealer id
    fetch(
      `${IP}/dealer/year/month/${year}/${sessionStorage.getItem("dealer_id")}`,
      {
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setcarinvoice(response); // invoice response is stored in state
      })
      .catch((err) => {
        console.log("error in invoice ", err);
      });
  };

  const inputRef = useRef(null);

  // called on click of pay now button and invoice data is fetched
  const payment = () => {
    fetch(
      `${IP}/dealer/sum/pay/${month}/${sessionStorage.getItem("dealer_id")}`,
      {
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        }),
      }
      
    )
      .then(async (res) => {
        const response = await res.json();
        setAmount(response); // set invoice data to the amount state
      })
      .catch((err) => {
        console.log("error in invoice ", err);
      });
  };

  // called when we hit the download button on the invoice
  const handleSaveFile = (e, month) => {
    e.preventDefault();

    // fetch the details of invoive by dealer id and month
    fetch(
      `${IP}/dealer/download/invoice/month/${sessionStorage.getItem("dealer_id")}/${month}`,
      {
        method: "GET",
        headers: new Headers({
          authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
          responseType: "blob",
        }),
      }
    )
      .then(async (res) => {
        var response = await res.json();
        setPdfLink(response["link"]); // in response we get a link of pdf to download
        document.getElementById("saveLink").click();
      })
      .catch((err) => {
        console.log("error in pdf link ", err.message);
      });
  };

  // un-approved dealers are redirected to the waiting page
  if (approve) {
    return (
      <div>
        <Redirect to="/dealer/waiting" />
      </div>
    );
  }

  // handle select to select the year from dropdown

  const handleSelect = (e) => {

    fetchInvoiceDetails(e.target.value);
  }

  return (
    <div>
      <a id="saveLink" href={pdfLink} target="_blank"></a>
      <div className="invoice">
        <h1 className={classes.head1}>INVOICE</h1>
        <InputLabel id="year" className="invoice-year" >
          Year:
        </InputLabel>
        <select className={classes.sel2} onChange={handleSelect}>
          <option value="0">Select Year:</option>
         {yearArray.map((item) => {
           return(
            <option value={item.value}>{item.label}</option>
           );
         })}
        </select>
      </div>

      {/* table */}
      <TableContainer component={Paper} className="invoice-table-card">
        {/* conditional rendering if car invoice is null show spinner else show data */}
        {carinvoice != null ? (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Month</TableCell>
                <TableCell align="center">Year</TableCell>
                <TableCell align="center">Invoice</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carinvoice.map((data, index) => (
                <TableRow key={index}>
                  {/* <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell> */}
                  <TableCell align="center">{data.id}</TableCell>
                  <TableCell align="center">{data.month}</TableCell>
                  <TableCell align="center">{data.year}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={(e) => {
                        handleSaveFile(e, data.month);
                      }}
                      className="invoice-download-btn-btn"
                    >
                      Download
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {/* If paid is 1 i.e true show paid else show pay now button */}
                    {data.paid ? (
                      <Button disabled className="payed-btn">
                        Paid
                      </Button>
                    ) : (
                      <Button
                        className={
                          data.month.slice(0, 3).toLowerCase() === new Date().toDateString().slice(4, 7).toLowerCase()
                            ? "paynow-btn-disabled"
                            : "paynow-btn"
                        }
                        onClick={() =>
                          setMonth(
                            (month = data.month),
                            payment(),
                            setPopup(!popup)
                          )
                        }
                        disabled={
                          data.month.slice(0, 3).toLowerCase() ==
                          new Date().toDateString().slice(4, 7).toLowerCase()
                            ? true
                            : false
                        }
                      >
                        Pay Now
                      </Button>
                    )}

                    {/* if amount is null then display null or else open popup showing the amount  */}
                    {amount == null ? null : (
                      <Dialog
                        onClose={() => setPopup(!popup)}
                        aria-labelledby="simple-dialog-title"
                        open={popup}
                      >
                        <DialogTitle id="simple-dialog-title">
                          One Step closer{" "}
                        </DialogTitle>
                        <DialogContent>
                          {"$" + amount[0].amount} will be charged for this
                          unpaid invoice.
                          <PayPal2 amount={amount[0].amount} />{" "}
                          {/* paypal component renderd here for payment gateway */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "1%",
                            }}
                          >
                            <Button
                              className="discard-button"
                              onClick={() =>
                                setPopup(
                                  (popup = !popup),
                                  window.location.reload() // reloads the page for latest information
                                )
                              }
                            >
                              Close
                            </Button>
                          </div>
                        </DialogContent>
                      </Dialog>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>
            <Spinner1 />
          </div>
        )}
      </TableContainer>
    </div>
  );
};
export default Invoice;
