import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import "./App.css";
import Footer from "./Footer/Footer";
import Invoice from "./pages/dealer/dealerinvoice/Invoice";
import Bids from "./pages/Customer/Bids";
import AddVehicle from "./pages/Customer/AddVehicle";
import React, { Component, useState } from "react";
import CustomerLogin from "./pages/customerlogin/CustomerLogin";
import Logout from "./pages/logout/Logout";
import LandingPage from "./home/LandingPage";
import ErrorPage from "./error/ErrorPage";
import DealerLogin from "./pages/dealer/dealerlogin/DealerLogin";
import Quotes from "./pages/dealer/quotes/Quotes";
import Inbox from "./pages/dealer/inbox/Inbox";
import Submitted_Quotes from "./pages/dealer/submittedquotes/Submitted_quotes";
import Profile from "./pages/dealer/dealerprofile/Profile";
import CustomerProfilePage from "./pages/Customer/profile/CustomerProfile";
import WaitingScreen from "./pages/dealer/waitingscreen/WaitingScreen";
import CustomerQuotes from "./pages/Customer/customerquotes/CustomerQuotes";
import Signup from "./pages/dealer/dealerlogin/Signup";
import CustomerSignupPage from "./pages/customerlogin/CustomerSignupPage";
import ResetPassword from "./ResetPassword/ResetPassword";
export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginRole: 0,
      resetTokenPath: "",
    };
  }
  componentDidMount() {
    // loginRole is set to 1,2,3 by customer, dealer, admin respectively
    if (sessionStorage.getItem("role") === "Customer") {
      this.setState({
        loginRole: 1,
      });
    } else if (sessionStorage.getItem("role") === "Dealer") {
      this.setState({
        loginRole: 2,
      });
    } else if (sessionStorage.getItem("role") === "admin") {
      this.setState({
        loginRole: 3,
      });
    }
    this.setState({
      resetTokenPath: `/user/resetpassword/${sessionStorage.getItem(
        "resetToken"
      )}`,
    });
    console.log(
      "login user id",
      this.state.loginRole,
      sessionStorage.getItem("resetToken")
    );
  }
  render() {
    return (
      <div>
        <Router>
          <Navbar />
          <Switch>
          <Route
              path={this.state.resetTokenPath}
              exact
              component={ResetPassword}
            />
            {this.state.loginRole === 2 ? ( // if role is 2 that is Dealer then this routes will be activated
              <Switch>
                <Route path="/dealer" exact component={Inbox} />
                <Route
                  path="/dealer/submittedquote"
                  exact
                  component={Submitted_Quotes}
                />
                <Route path="/dealer/quote/:id" exact component={Quotes} />
                <Route path="/dealer/invoice" exact component={Invoice} />
                <Route path="/dealer/profile" exact component={Profile} />
                <Route path="/dealer/waiting" exact component={WaitingScreen} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/" component={LandingPage} />
                <Route component={ErrorPage} />
              </Switch>
            ) : (
              <div>
                {this.state.loginRole === 1 ? ( // if role is 1 that is Customer then this routes will be activated
                  <div>
                    <Switch>
                      <Route
                        path="/customer"
                        exact
                        component={CustomerQuotes}
                      />

                      <Route
                        path="/customer/quote/bid/:id"
                        exact
                        component={Bids}
                      />
                      <Route
                        path="/customer/quote"
                        exact
                        component={AddVehicle}
                      />
                      <Route
                        path="/customer/profile"
                        exact
                        component={CustomerProfilePage}
                      />
                      <Route exact path="/logout" component={Logout} />
                      <Route exact path="/" component={LandingPage} />
                      <Route component={ErrorPage} />
                    </Switch>
                  </div>
                ) : (
                  <div>
                    {this.state.loginRole === 0 ? ( // if role is 0 that is Home then this routes will be activated
                      <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route
                          path="/dealer/login"
                          exact
                          component={DealerLogin}
                        />
                        <Route
                          path="/customer/login"
                          exact
                          component={CustomerLogin}
                        />
                        <Route
                          path="/customer/signup"
                          exact
                          component={CustomerSignupPage}
                        />
                        <Route path="/dealer/signup" exact component={Signup} />
                        <Route exact path="/logout" component={Logout} />
                        <Route component={LandingPage} />
                      </Switch>
                    ) : (
                      <div>
                        <Switch>
                          <Route component={ErrorPage} />
                        </Switch>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {/* If routes are not from any mentioned above shows error page */}
            <Route component={ErrorPage} />
            <Route
              path="/user/resetpassword"
              exact
              component={ResetPassword}
            />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;
//https://github.com/briancodex/react-navbar-v1/tree/master/src/components
