import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import "./Signup.css";

import { Checkbox, CircularProgress, Grid, InputAdornment, Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { IP } from "../../../ip/IPConfig";

import { firebasecode } from "../../../../firebase/firebase";
import firebase from "firebase";
import Select from "react-select";
import swal from "sweetalert";
import { isPhoneValid, isZipcode } from "../../../ValidationFunctions";

import { auth } from "../../../../firebase/firebase";

function Signup() {
  // signup more details form pupup open state
  const [open, setOpen] = React.useState(false);

  // show password checkbox state
  const [showPass, setShowPass] = useState(false);

  // user information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dealerShipName, setDealerShipName] = useState("");
  const [dealerAddress, setDealerAddress] = useState("");
  let [emailId, setEmailId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [state, setStateR] = useState(null);
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");

  // passwords match flag
  const [passMatch, setPassMatch] = useState(false);

  // redirect flag
  const [redirect, setRedirect] = useState(false);

  // car makers array
  const [carBrandNames, setCarBrandNames] = useState([]);

  // multiple car selected by user are stored in this array
  const [carSelect, setcarSelect] = useState([]);

  // email array for multiple emails
  let [emailArray, setemailArray] = useState([]);

  // email errors
  let [emailError, setEmailError] = useState("");

  // google signup flag
  let [isGoogleSignup, setIsGoogleSignup] = useState(false);

  // google signnup reponse
  let [googleSignupRes, setGoogleSignupRes] = useState();

  // state check variables
  let [phoneCheck, setPhoneCheck] = useState(false);
  let [stateCheck, setStateCheck] = useState(false);
  let [carSelectCheck, setCarSelectCheck] = useState(false);
  let [zipcodeValidityCheck, setzipcodeValidityCheck] = useState(true);

  const [userEmailErr, setUserEmailErr] = useState(false);

  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);

  const [loader, setLoader] = useState(false);

  var passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/; // password check regex

  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if (isZipcode(zipcode)) {
    zipcodeValidityCheck = true;
  } else {
    zipcodeValidityCheck = false;
  }

  useEffect(() => {
    console.log("====================================");
    console.log("dealer signup");
    console.log("====================================");

    // car makers fetch from server
    fetch(`${IP}/car/get/carmaker`)
      .then(async (res) => {
        var response = [];
        response = await res.json();
        console.log(response);
        response.forEach((element) => {
          var carBrand_ = {
            value: element["car_maker"],
            label: element["car_maker"],
          };
          setCarBrandNames((carBrandNames) => [...carBrandNames, carBrand_]); // set car brands in state
        });
      })
      .catch((err) => {
        console.log("====================================");
        console.log("error in fetchig cars");
        console.log("====================================");
      });
  }, []);

  // assigns the input values to the corresponding states
  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
      if (e.target.value.match(format)) {
        setFirstNameErr(true);
      } else {
        setFirstNameErr(false);
      }
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
      if (e.target.value.match(format)) {
        setLastNameErr(true);
      } else {
        setLastNameErr(false);
      }
    } else if (e.target.name === "userName") {
      setUserName(e.target.value);
      if (e.target.value.indexOf("@") <= 0) {
        setUserEmailErr(true);
      }
      if (e.target.value.indexOf("@") > 0) {
        setUserEmailErr(false);
      }
      if (e.target.value.charAt(e.target.value.length - 4) != ".") {
        setUserEmailErr(true);
      } else {
        setUserEmailErr(false);
      }
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
      if (confirmPassword === e.target.value) {
        setPassMatch(false);
      } else {
        setPassMatch(true);
      }
    } else if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
      if (password === e.target.value) {
        setPassMatch(false);
      } else {
        setPassMatch(true);
      }
    } else if (e.target.name === "showPass") {
      setShowPass(!showPass);
    } else if (e.target.name === "dealerShipName") {
      setDealerShipName(e.target.value);
    } else if (e.target.name === "dealerAddress") {
      setDealerAddress(e.target.value);
    } else if (e.target.name === "emailId") {
      setEmailId(e.target.value);
      if (emailId.length == 0) {
        setEmailError((emailError = null));
      }
    } else if (e.target.name === "phoneNumber") {
      if (e.target.value < 0) {
        setPhoneNumber(e.target.value * -1); // this ensures that number entered is always positive
      } else {
        setPhoneNumber(e.target.value);
      }
    } else if (e.target.name === "state") {
      setStateR(e.target.value["value"]);
    } else if (e.target.name === "zipcode") {
      setZipcode(e.target.value < 0 ? e.target.value * -1 : e.target.value);
    } else if (e.target.name === "country") {
      setCountry(e.target.value);
    }
  };

  // checking if the all fields are filled and valid
  phoneCheck = isPhoneValid(phoneNumber);

  // *********************************Multiple Emails****************************************

  // email validity check
  const isValid = (email) => {
    // check is email is already present in the array
    if (isInList(email)) {
      emailError = `${email} has already been added.`;
    }

    // check email validity
    if (!isEmail(email)) {
      emailError = `${email} is not a valid email address.`;
    }

    // set emailError to display
    if (emailError) {
      setEmailError(emailError);

      return false;
    }

    return true;
  };

  // function for checking if email is present in list
  const isInList = (email) => {
    return emailArray.includes(email);
  };

  // function to check if email valid
  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  // delete email from the array
  const handleDelete = (item) => {
    setemailArray((emailArray = emailArray.filter((i) => i !== item)));
  };

  // add email in the array of emails
  const addEmail = (event) => {
    var email = emailId.trim(); // removes extra spaces from the string if present

    if (isValid(email)) {
      let tempArr = [...emailArray];
      tempArr.push(email);
      setemailArray((emailArray = tempArr));
      setEmailId("");
    }
  };

  // ****************************************************************************************

  // opens the popup for entering more information about dealer
  const handleClickOpen = (e) => {
    e.preventDefault();
    console.log(
      firstName,
      lastName,
      userName,
      password,
      confirmPassword,
      showPass
    );

    // set the first entered email address in the array
    let tempArr = [...emailArray];
    tempArr.push(userName);
    setemailArray((emailArray = tempArr));
    setEmailId("");

    // state used to open the popup
    setOpen(true);

    // ste google signup flag to false
    setIsGoogleSignup(false);
  };

  // function uses to close the popup
  const handleClose = () => {
    setOpen(false);
    setPassword("");
    setemailArray([]);
    setConfirmPassword("");
    setUserName("");
  };
  // google signup function
  const handleGoogleSignUp = (e) => {
    e.preventDefault();
    console.log("google login");
    const provider = new firebase.auth.GoogleAuthProvider(); // google firbase auth provider
    firebasecode
      .auth()
      .signInWithPopup(provider) // sign in with a popup window
      .then((result) => {
        /* @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;

        setUserName([user.displayName, ""]);
        auth.currentUser
          .getIdToken(true)
          .then((idToken) => {
            sessionStorage.setItem("idToken", idToken);
            setTimeout(setUserName[(user.displayName, idToken)], 1800);
          })
          .catch((error) => {
            // Handle error
            setLoader(false);
            alert("not gettng token");
          });

        console.log("user :- ", user);
        console.log(
          user.displayName,
          user.email,
          user.photoURL,
          user.phoneNumber,
          user.uid
        );

        // call to the backend googleSignup api to store the informtion of user we gate from google
        fetch(`${IP}/dealer/googlesignup`, {
          method: "post",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify({
            user_email: user.email,
            user_password: user.uid,
          }),
        })
          .then(async (res) => {
            let response = await res.json();

            // set the google signup to true its important for updating the information
            setIsGoogleSignup(true);
            if (response.msg.includes("Email Already Exists")) {
              console.log("Login Response", response);
              swal("Email-Id Already Exists", "Try Logging In", "info");
            } else {
              console.log(response);
              // set google signup response to the response
              setGoogleSignupRes(response);

              // storing the needed information in session storage
              sessionStorage.setItem("role", response["role"]);
              sessionStorage.setItem("user_id", response["data"][0]["user_id"]);
              sessionStorage.setItem(
                "dealer_id",
                response["data"][0]["dealer_id"]
              );
              sessionStorage.setItem("approved", response["data"][0]["status"]);

              // store the email provided as primary email i.e at index 0 of email array
              let tempArr = [...emailArray];
              tempArr.push(user.email);
              setemailArray((emailArray = tempArr));
              setEmailId("");

              // open additional information popup
              setOpen(true);
            }
          })
          .catch((err) => {
            setLoader(false);
            console.log("error in login ", err);
            swal("Error In Signup", "Try Again", "info");
          });
      })
      .catch((error) => {
        setLoader(false);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        swal("Error!", "Check Your Connection!", "error");
      });
  };

  // on clicking save button this function is called
  const handleSave = (e) => {
    e.preventDefault();
    console.log(
      firstName,
      lastName,
      userName,
      password,
      emailArray,
      dealerAddress,
      state,
      zipcode,
      phoneNumber,
      country,
      dealerShipName,
      carSelect
      // googleSignupRes["data"][0]["dealer_id"],
      // googleSignupRes["data"][0]["user_id"],
    );
    // creating profile and loggin in

    setLoader(true);

    if (isPhoneValid(phoneNumber) && carSelect.length > 0 && state !== null) {
      // check if phone number is valid

      // check if google signup is true
      if (isGoogleSignup) {
        // update the user with the values entered in the popup
        fetch(`${IP}/dealer/update/add/email/brand`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_firstname: userName[0].split(" ")[0],
            user_lastname: userName[0].split(" ")[1],
            user_name: userName,
            user_email: emailArray,
            user_phn: phoneNumber,
            dealer_company: dealerShipName,
            dealer_brand: carSelect,
            user_address: dealerAddress,
            user_state: state,
            user_pincode: zipcode,
            user_country: country,
            dealer_id: googleSignupRes["data"][0]["dealer_id"],
            user_id: googleSignupRes["data"][0]["user_id"],
          }),
        })
          .then(async (res) => {
            sessionStorage.setItem("dealer_name", firstName);
            var response = await res.text();
            handleLogin(emailArray[0], userName[1]);
          })
          .catch((err) => {
            setLoader(false);
            console.log("====================================");
            console.log("error in signup ", err);
            console.log("====================================");
            swal("Error in Signup", "try again", "info");
          });

        // if googleSignup is false normal signup is done
      } else {
        // call to the backend api for signup dealer
        fetch(`${IP}/dealer/signupdealer/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_firstname: firstName,
            user_lastname: lastName,
            user_name: userName,
            user_email: emailArray,
            user_password: password,
            user_address: dealerAddress,
            user_state: state,
            user_pincode: zipcode,
            user_phn: phoneNumber,
            user_country: "USA",
            dealer_company: dealerShipName,
            dealer_brand: carSelect,
          }),
        })
          .then(async (res) => {
            var response = await res.json();
            if (response.msg === "logged in successfully") {
              // response = await JSON.parse(response);

              console.log("====================================");
              console.log("signup response", response);
              console.log("====================================");
              // sessionStorage.setItem("token", response["token"]);
              sessionStorage.setItem("role", response["role"]);
              sessionStorage.setItem(
                "user_id",
                response["userDetail"][0]["user_id"]
              );
              sessionStorage.setItem(
                "dealer_id",
                response["userDetail"][0]["dealer_id"]
              );
              sessionStorage.setItem(
                "dealer_name",
                response["userDetail"][0]["user_firstname"]
              );
              sessionStorage.setItem(
                "approved",
                response["userDetail"][0]["status"]
              );
              // setRedirect(true);
              // window.location.reload();
              sessionStorage.setItem("Login", true);
              setTimeout(() => {
                googleLogin(response["userDetail"][0]["user_email"], password);
              }, 2000);
            } else {
              setLoader(false);
              console.log("====================================");
              console.log("signup response", response);
              console.log("====================================");
              swal(response, "Try Logging In", "info");
            }
          })
          .catch((err) => {
            setLoader(false);
            console.log("====================================");
            console.log("error in signup ", err);
            console.log("====================================");
            swal("Error In SignUp!", "please try again", "error");
          });
      }
    } else {
      // alert("Enter valid values");
    }
  };

  const handleLogin = (emailId, token = null) => {
    console.log(token);
    // try catch for exception handling4
    fetch(`${IP}/dealer/login`, {
      // fetching the data from the server
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${
          token ? token : sessionStorage.getItem("idToken")
        }`,
        // authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        user_email: emailId,
        // user_password: password,
      }),
    })
      .then(async (res) => {
        // after successful login response and the required data is stored in the session for use in other components
        const response = await res.json();
        // const response = await res.text();

        // response is stored in session storage
        sessionStorage.setItem("dealerLoginResponse", JSON.stringify(response));
        sessionStorage.setItem("role", response["role"]);
        sessionStorage.setItem("user_id", response["result"][0]["user_id"]);
        sessionStorage.setItem("dealer_id", response["result"][0]["dealer_id"]);
        sessionStorage.setItem(
          "dealer_name",
          response["result"][0]["user_firstname"]
        );
        sessionStorage.setItem("approved", response["result"][0]["status"]);

        // loader and login flags status changed for redirecting to the dealer dashboard
        sessionStorage.setItem("Login", true);
        setRedirect(true);

        swal("Success", "User Created successfully", "success");
        setLoader(false);
        // reload required to render the dealer page
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => {
        // catching the error in login if any
        console.log("error in login ", err);
        console.log("error in login ", err["message"]);
        setLoader(false);
      });
  };

  const googleLogin = (email, password1) => {
    try {
      auth
        .signInWithEmailAndPassword(email, password1)
        .then(() => {
          auth.currentUser
            .getIdToken(true)
            .then((idToken) => {
              sessionStorage.setItem("idToken", idToken);
              swal("User Created successfully", "<<>>", "success");
              setLoader(false);
              setTimeout(setRedirect(true), window.location.reload(), 1800);
            })
            .catch((error) => {
              // Handle error
              alert("not gettng token");
            });
        })
        .catch((err) => {
          setLoader(false);
          if (err.code == "auth/user-not-found") {
            // this.setState({ ValidEmail: true, loader: false });
          }
          if (err.code == "auth/wrong-password") {
            // this.setState({ WrongPass: true, loader: false });
          }
          // auth/user-not-found
          // auth/wrong-password

          console.log("error ", err.code, err.message);
        });
    } catch (err) {
      // this.setState({ WrongPass: true, loader: false });
      console.log(err);
      setLoader(false);
    }
  };

  const fieldsCheck = () => {
    console.log("In field check");
    if (state == null) {
      setStateCheck(true);
    } else if (carSelect.length == 0) {
      setCarSelectCheck(true);
    }
  };

  if (state !== null) {
    stateCheck = false;
  }
  if (carSelect.length > 0) {
    carSelectCheck = false;
  }

  //close popup on back button click

  const closePopup = () => {
    setOpen(false);
    setPassword("");
    setemailArray([]);
    setConfirmPassword("");
    setUserName("");
  };

  // if redirect is set true i.e login success it will redirect to delaer page
  if (redirect) {
    return (
      <div>
        <Redirect to="/dealer" />
      </div>
    );
  }
  return (
    <div className="signupform">
      <h2>Dealer Sign Up</h2>
      <br />
      <form onSubmit={handleClickOpen}>
        <Grid container spacing="2">
          <Grid item sm={6} xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="First Name"
              name="firstName"
              onChange={handleChange}
            />
            {firstNameErr == true ? (
              <p className="Error_messages">
                Special characters are not allowed here
              </p>
            ) : (
              ""
            )}
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Last Name"
              name="lastName"
              onChange={handleChange}
            />

            {lastNameErr == true ? (
              <p className="Error_messages">
                Special characters are not allowed here
              </p>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Email Id"
              name="userName"
              onChange={handleChange}
              type="email"
              value={userName}
              autoComplete="off"
            />
          </Grid>

          {userEmailErr == true ? (
            <p className="Error_messages">Please enter valid email-id</p>
          ) : null}

          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              // error={password.match(passw) ? false : true}
              // helperText={errorText1}
              required
              fullWidth
              value={password}
              type={showPass ? "text" : "password"}
              label="Password"
              name="password"
              onChange={handleChange}
              helperText={
                password !== "" && password.length < 6
                  ? "Password must have atleast 6 characters"
                  : null
              }
              error={password !== "" && password.length < 6}
            />
          </Grid>
          <Grid xs={12} sm={12} item>
            <TextField
              variant="outlined"
              // error={confirmPassword.match(passw) ? false : true}
              // helperText={errorText2}
              required
              fullWidth
              type={showPass ? "text" : "password"}
              value={confirmPassword}
              label="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
            />
            {passMatch ? (
              <label style={{ color: "red" }}>Password didn't match</label>
            ) : (
              <div></div>
            )}
          </Grid>
          <Grid item xs={2} sm={1}>
            <Checkbox
              defaultChecked={showPass}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
              onChange={handleChange}
              name="showPass"
            />
          </Grid>
          <Grid item xs={8} sm={5}>
            <Typography style={{ marginTop: "1%" }}>show password</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h3 style={{ textAlign: "center" }}>OR</h3>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="google-btn" onClick={handleGoogleSignUp}>
              <div style={{ marginTop: "1%", fontSize: "0.5cm" }}>
                SignUp with google
              </div>
              <img
                style={{ marginTop: "0%", width: "10%" }}
                src="https://img.icons8.com/fluent/48/000000/google-logo.png"
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div className="buttons">
              <button
                className="signup-next-btn"
                type="submit"
                disabled={passMatch || password.length < 6}
              >
                Next
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        style={{ borderRadius: "40" }}
        aria-labelledby="form-dialog-title"
        className="signup-dialog"
      >
        <DialogTitle id="form-dialog-title">
          Please fill the below details
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave} id="form">
            <Grid container spacing="2">
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  name="dealerShipName"
                  required
                  fullWidth
                  label="Dealership Name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  name="dealerAddress"
                  required
                  fullWidth
                  label="Dealership Address"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* display the email array */}
                <div className="alternate-emails">
                  {emailArray.map((item, index) =>
                    index === 0 ? (
                      <div className="tag-item" key={item}>
                        <span>{item}</span>
                      </div>
                    ) : (
                      <span className="tag-item" key={item}>
                        <span>{item}</span>
                        <span
                          type="button"
                          className="email-cancel-button"
                          onClick={() => handleDelete(item)}
                        >
                          X
                        </span>
                      </span>
                    )
                  )}
                </div>

                {/* if email not valid then show a error msg */}
                {emailError === "" ? (
                  <div></div>
                ) : (
                  <p className="error-msg">{emailError}</p>
                )}
                <div>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="emailId"
                    fullWidth
                    type="email"
                    label="Alternate Email-Ids "
                    value={emailId}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i
                            className="fa fa-plus plus-icon"
                            onClick={addEmail}
                          ></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: "grid" }}>
                <div className="bids-header-dropdown">
                  <Select
                    required
                    isMulti
                    placeholder="Car Brands"
                    classNamePrefix="select"
                    options={carBrandNames}
                    onChange={(value) => {
                      setcarSelect([]);
                      value.map((element, index) => {
                        setcarSelect((carSelect) => [
                          ...carSelect,
                          element["value"],
                        ]);
                      });
                    }}
                    name="carSelect"
                    className={carSelectCheck ? "red-border-select" : ""}
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6} style={{ display: "grid" }}>
                <Select
                  required
                  placeholder="State"
                  classNamePrefix="select"
                  options={[
                    { value: "Alabama", label: "Alabama" },
                    { value: "Alaska", label: "Alaska" },
                    { value: "Arizona", label: "Arizona" },
                    { value: "Arkansas", label: "Arkansas" },
                    { value: "California", label: "California" },
                    { value: "Colorado", label: "Colorado" },
                    { value: "Connecticut", label: "Connecticut" },
                    { value: "Delaware", label: "Delaware" },
                    { value: "Florida", label: "Florida" },
                    { value: "Georgia", label: "Georgia" },
                    { value: "Hawaii", label: "Hawaii" },
                    { value: "Illinois", label: "Illinois" },
                    { value: "Idaho", label: "Idaho" },
                    { value: "Indiana", label: "Indiana" },
                    { value: "Iowa", label: "Iowa" },
                    { value: "Kansas", label: "Kansas" },
                    { value: "Kentucky", label: "Kentucky" },
                    { value: "Louisiana", label: "Louisiana" },
                    { value: "Maine", label: "Maine" },
                    { value: "Maryland", label: "Maryland" },
                    { value: "Massachusetts", label: "Massachusetts" },
                    { value: "Michigan", label: "Michigan" },
                    { value: "Minnesota", label: "Minnesota" },
                    { value: "Mississippi", label: "Mississippi" },
                    { value: "Missouri", label: "Missouri" },
                    { value: "Montana", label: "Montana" },
                    { value: "Nebraska", label: "Nebraska" },
                    { value: "Nevada", label: "Nevada" },
                    { value: "New Hampshire", label: "New Hampshire" },
                    { value: "New Jersey", label: "New Jersey" },
                    { value: "New Mexico", label: "New Mexico" },
                    { value: "New York", label: "New York" },
                    { value: "North Carolina", label: "North Carolina" },
                    { value: "North Dakota", label: "North Dakota" },
                    { value: "Ohio", label: "Ohio" },
                    { value: "Oklahoma", label: "Oklahoma" },
                    { value: "Oregon", label: "Oregon" },
                    { value: "Pennsylvania", label: "Pennsylvania" },
                    { value: "Rhode Island", label: "Rhode Island" },
                    { value: "South Carolina", label: "South Carolina" },
                    { value: "South Dakota", label: "South Dakota" },
                    { value: "Tennessee", label: "Tennessee" },
                    { value: "Texas", label: "Texas" },
                    { value: "Utah", label: "Utah" },
                    { value: "Vermont", label: "Vermont" },
                    { value: "Virginia", label: "Virginia" },
                    { value: "Washington", label: "Washington" },
                    { value: "West Virginia", label: "West Virginia" },
                    { value: "Wisconsin", label: "Wisconsin" },
                    { value: "Wyoming", label: "Wyoming" },
                  ]}
                  onChange={(value) => {
                    setStateR(value["value"]);
                  }}
                  name="state"
                  className={stateCheck ? "red-border-select" : ""}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  type="tel"
                  name="zipcode"
                  required
                  fullWidth
                  label="Zip Code"
                  value={zipcode}
                  error={!zipcodeValidityCheck}
                />
                {!zipcodeValidityCheck ? (
                  <p className="validation-error">Enter valid zipcode</p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  name="phoneNumber"
                  required
                  fullWidth
                  label="Contact Number"
                  value={phoneNumber}
                  type="tel"
                  error={!phoneCheck}
                />
                {phoneCheck ? null : ( // phone number validity check
                  <p className="validation-error">
                    Enter 10 digits mobile number
                  </p>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  type="text"
                  name="country"
                  required
                  fullWidth
                  value="USA"
                  disabled
                />
              </Grid>
            </Grid>
            <DialogActions className="dialog-action-buttons">
              <Button
                className="dialog-back-button"
                color="primary"
                variant="outlined"
                onClick={closePopup}
              >
                <i className="fas fa-chevron-left"></i>
                Back
              </Button>
              <Button
                onClick={() => fieldsCheck()}
                type="submit"
                className="signup-profile-save-btn"
                variant="contained"
                disabled={loader}
              >
                {loader ? <CircularProgress size={25} /> : "Save"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Signup;
