import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";

import "./CustomerSignUp.css";
import {
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { IP } from "../../ip/IPConfig";
import { auth, firebasecode } from "../../../firebase/firebase";
import firebase from "firebase";
import swal from "sweetalert";
import { isEmail, isPhoneValid, isZipcode } from "../../ValidationFunctions";

function CustomerSignupPage() {
  // state used to open the sign up pop up
  const [open, setOpen] = React.useState(false);

  // show password checkbox state
  const [showPass, setShowPass] = useState(false);

  // user information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [customerAddress, setcustomerAddress] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  //helper text for password text fields
  const [errorText1, setErrorText1] = useState("");
  const [errorText2, setErrorText2] = useState("");

  //this state active when first and last name contains special character
  const [fNameErr, setFNameErr] = useState(false);
  const [lNameErr, setLNameErr] = useState(false);

  const [userEmailErr, setUserEmailErr] = useState(false);

  //matching password flag
  const [passMatch, setPassMatch] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // google idToken
  const [token, setToken] = useState("");
  const [isGoogleSignup, setIsGoogleSignup] = useState(false);

  const [loader, setLoader] = useState(false);

  let [zipcodeValidityCheck, setzipcodeValidityCheck] = useState(true);

  var passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/; // password regex

  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if (isZipcode(zipcode)) {
    zipcodeValidityCheck = true;
  } else {
    zipcodeValidityCheck = false;
  }

  // handleChange function puts in the input values into theie respective states
  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
      if (e.target.value.match(format)) {
        setFNameErr(true);
      } else {
        setFNameErr(false);
      }
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
      if (e.target.value.match(format)) {
        setLNameErr(true);
      } else {
        setLNameErr(false);
      }
    } else if (e.target.name === "userName") {
      setUserName(e.target.value);
      setEmailId(e.target.value);

      if (e.target.value.indexOf("@") <= 0) {
        setUserEmailErr(true);
      }
      if (e.target.value.indexOf("@") > 0) {
        setUserEmailErr(false);
      }
      if (e.target.value.charAt(e.target.value.length - 4) != ".") {
        setUserEmailErr(true);
      } else {
        setUserEmailErr(false);
      }
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
      if (confirmPassword === e.target.value) {
        // if password dont match show error
        setPassMatch(false);
      } else {
        setPassMatch(true);
      }
      if (e.target.value.match(passw)) {
        setErrorText1("");
      } else {
        setErrorText1("Strong password");
      }
    } else if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
      if (password === e.target.value) {
        // if password dont match show error
        setPassMatch(false);
      } else {
        setPassMatch(true);
      }
      if (e.target.value.match(passw)) {
        setErrorText2("");
      } else {
        setErrorText2("Strong password");
      }
    } else if (e.target.name === "showPass") {
      setShowPass(!showPass);
    } else if (e.target.name === "country") {
      setCountry(e.target.value);
    } else if (e.target.name === "customerAddress") {
      setcustomerAddress(e.target.value);
    } else if (e.target.name === "emailId") {
      setEmailId(e.target.value);
    } else if (e.target.name === "phoneNumber") {
      setPhoneNumber(e.target.value < 0 ? e.target.value * -1 : e.target.value); // taking no negtive value for number
    } else if (e.target.name === "state") {
      setState(e.target.value);
    } else if (e.target.name === "zipcode") {
      setZipcode(e.target.value < 0 ? e.target.value * -1 : e.target.value); // taking no negtive value for number
    }
  };

  // on next button click thus function is called
  const handleClickOpen = (e) => {
    e.preventDefault();
    console.log(
      firstName,
      lastName,
      userName,
      password,
      confirmPassword,
      showPass
    );
    setOpen(true);
  };

  // function used to close the popup
  const handleClose = () => {
    setOpen(false);
  };

  // this function is called when we click the save button
  const handleSave = (e) => {
    e.preventDefault();

    setLoader(true);
    sessionStorage.setItem("user_name", firstName);
    if (!isGoogleSignup) {
      // creating profile and loggin in
      if (isPhoneValid(phoneNumber) && isZipcode(zipcode) && state != "") {
        // checking the phone number validity
        fetch(`${IP}/customer/signupcustomer/`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_firstname: firstName,
            user_lastname: lastName,
            user_name: userName,
            user_email: [emailId],
            user_password: password,
            user_address: customerAddress,
            user_state: state,
            user_pincode: zipcode,
            user_phn: phoneNumber,
            user_country: "USA",
          }),
        })
          .then(async (res) => {
            const response = await res.text();
            afterSignup(response);
          })
          .catch((err) => {
            swal("Error in SignUp", { err }, "error"); // error alert
            setLoader(false);
          });
      } else {
        alert("Enter Valid Values");
        setLoader(false);
      }
    } else {
      fetch(
        `${IP}/customer/profile/update/${sessionStorage.getItem("customerId")}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
          },
          body: JSON.stringify({
            user_firstname: firstName,
            user_lastname: lastName,
            user_address: customerAddress,
            user_email: emailId,
            user_state: state,
            user_pincode: zipcode,
            user_phn: phoneNumber,
            user_country: "USA",
          }),
        }
      )
        .then(async (res) => {
          const response = await res.json();
          console.log(response);
          handleLogin(emailId, sessionStorage.getItem("idToken"));
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const afterSignup = async (response) => {
    if (!response.includes("Email-Id is already registered")) {
      response = JSON.parse(response);
      console.log("////");
      console.log("signup response", response);
      console.log("///");

      // login response parameters stored in session storage for use in other components
      sessionStorage.setItem("role", response["role"]);
      sessionStorage.setItem("user_name", response.info[0]["user_firstname"]);
      sessionStorage.setItem("user_email", response.info[0]["user_email"]);
      sessionStorage.setItem("user_phone", response.info[0]["user_phn"]);
      sessionStorage.setItem("customer_id", response.info[0]["customer_id"]);
      sessionStorage.setItem("signupResponse", JSON.stringify(response));

      // alert for user creation

      setTimeout(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(response.info[0]["user_email"], password)
          .then((userCredential) => {
            auth.currentUser.getIdToken(true).then((idToken) => {
              sessionStorage.setItem("idToken", idToken);
              handleLogin(response.info[0]["user_email"], idToken);
            });
            var user = userCredential.user;
            // ...
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            setLoader(false);
          });
      }, 2000);
      // user redirected to customer page
      // setRedirect(true);
      // sessionStorage.setItem("Login", true);
      // window.location.reload(); // reloading necessary for rendering component
    } else {
      console.log("////");
      console.log("signup response", response);
      console.log("///");
      swal(response, "Try Logging In", "info"); // error alert
      setLoader(false);
    }
  };

  // login after signup
  const handleLogin = (emailId, token = null) => {
    console.log(token);
    // try catch for exception handling4
    fetch(`${IP}/customer/login`, {
      // fetching the data from the server
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${
          token ? token : sessionStorage.getItem("idToken")
        }`,
      },
      body: JSON.stringify({
        user_email: emailId,
        // user_password: "password",
      }),
    })
      .then(async (res) => {
        // after successful login response and the required data is stored in the session for use in other components
        const response = await res.json();
        // const response = await res.text();

        console.log("Login Success response", response);
        sessionStorage.setItem("role", response["role"]);
        sessionStorage.setItem(
          "user_name",
          response.result[0]["user_firstname"]
        );
        // sessionStorage.setItem("token", response["token"]);
        sessionStorage.setItem("customerId", response.result[0]["customer_id"]);
        sessionStorage.setItem("user_email", response.result[0]["user_email"]);
        sessionStorage.setItem("user_phone", response.result[0]["user_phn"]);

        sessionStorage.setItem("Login", true);
        setRedirect(true);
        swal("Success", "User Created Successfully", "success");
        setLoader(false);
        // reload required to render the dealer page
        window.location.reload();
      })
      .catch((err) => {
        // catching the error in login if any
        console.log("error in login ", err);
        console.log("error in login ", err["message"]);
        setLoader(false);
      });
  };

  // google signup user
  const handleGoogleSignUp = (e) => {
    e.preventDefault();
    setIsGoogleSignup(true);
    console.log("google login");
    const provider = new firebase.auth.GoogleAuthProvider(); // firebase google auth provider
    firebasecode
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /* @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        const email = result.user.email;
        console.log("user", user);
        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            // Send token to your backend via HTTPS
            // ...
            setToken(idToken);
            console.log(idToken);
            sessionStorage.setItem("idToken", idToken);

            fetch(`${IP}/customer/googlesignup`, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
              body: JSON.stringify({
                user_email: email,
                user_password: "password",
              }),
            })
              .then(async (res) => {
                const response = await res.json();
                console.log(response);
                sessionStorage.setItem(
                  "customerId",
                  response["info"][0]["customer_id"]
                );
              })
              .catch((err) => {
                console.log(err);
                setLoader(false);
              });
          })
          .catch((error) => {
            // Handle error
            console.log(error);
            setLoader(false);
          });
        console.log(
          user.displayName,
          user.email,
          user.photoURL,
          user.phoneNumber
        );

        // store values we get from google sign up into states and then open pop up of more information
        var UserName = user["displayName"].split(" ");
        setFirstName(UserName[0]);
        setLastName(UserName[1]);
        setUserName(UserName);
        setPassword(user.uid);
        setEmailId(user.email);
        setOpen(true); // more information pop up open
      })
      .catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
        swal("Error!", "Check Your Connection!", "error");
        setLoader(false);
      });
  };

  // if redirect flag is true redirects to customer home page
  if (redirect) {
    return <Redirect to="/customer/" />;
  }
  return (
    <div className="Signupform">
      <h2>Customer Sign Up</h2>
      <br />
      <form onSubmit={handleClickOpen}>
        <Grid container spacing="2">
          <Grid item sm={6} xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="First Name"
              name="firstName"
              onChange={handleChange}
            />
            {fNameErr == true ? (
              <p className="validation-error">
                Special characters are not allowed here
              </p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Last Name"
              name="lastName"
              onChange={handleChange}
            />
            {lNameErr == true ? (
              <p className="validation-error">
                Special characters are not allowed here
              </p>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Email Id"
              name="userName"
              onChange={handleChange}
              type="email"
            />
            {userEmailErr == true ? (
              <p className="validation-error">Please enter valid email-id</p>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              // error={password.match(passw) ? false : true}
              fullWidth
              value={password}
              type={showPass ? "text" : "password"}
              label="Password"
              name="password"
              onChange={handleChange}
              helperText={
                password !== "" && password.length < 6
                  ? "Password must have atleast 6 characters"
                  : null
              }
              error={password !== "" && password.length < 6}
            />
          </Grid>
          <Grid xs={12} sm={12} item>
            <TextField
              variant="outlined"
              // error={confirmPassword.match(passw) ? false : true}
              required
              fullWidth
              value={confirmPassword}
              type={showPass ? "text" : "password"}
              label="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
            />
            {passMatch ? ( // password matching check
              <label className="validation-error">Password didn't match</label>
            ) : (
              <div></div>
            )}
          </Grid>
          <Grid item xs={1} sm={1}>
            <Checkbox
              defaultChecked={showPass}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
              onChange={handleChange}
              name="showPass"
            />
          </Grid>
          <Grid item xs={11} sm={11}>
            <Typography style={{ marginTop: "1%" }}>show password</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h3 style={{ textAlign: "center" }}>OR</h3>
            <br />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="google-btn" onClick={handleGoogleSignUp}>
              <div style={{ marginTop: "1%", fontSize: "0.5cm" }}>
                SignUp with google
              </div>
              <img
                style={{ marginTop: "0%", width: "10%" }}
                src="https://img.icons8.com/fluent/48/000000/google-logo.png"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="buttons">
              <button
                className="signup-next-btn"
                type="submit"
                disabled={passMatch || password.length < 6}
              >
                Next
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        style={{ borderRadius: "40" }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Please fill the below details
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave} className="dialog-form">
            <Grid container spacing="2">
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  value={emailId}
                  onChange={handleChange}
                  name="emailId"
                  helperText={
                    isEmail(emailId) ? null : "Enter a valid Email Id"
                  }
                  required
                  fullWidth
                  type="email"
                  label="Email Adress"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  name="phoneNumber"
                  type="tel"
                  required
                  fullWidth
                  label="Contact Number"
                  value={phoneNumber}
                  error={!isPhoneValid(phoneNumber)}
                />
                {isPhoneValid(phoneNumber) ? null : ( // phone number validity check
                  <p className="validation-error">
                    Enter enter 10-digits mobile number
                  </p>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  placeholder="State"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  // styles={{option:styles => ({...styles, backgroundColor: '#01ACED',color:"white"})}}
                  options={[
                    { value: "Alabama", label: "Alabama" },
                    { value: "Alaska", label: "Alaska" },
                    { value: "Arizona", label: "Arizona" },
                    { value: "Arkansas", label: "Arkansas" },
                    { value: "California", label: "California" },
                    { value: "Colorado", label: "Colorado" },
                    { value: "Connecticut", label: "Connecticut" },
                    { value: "Delaware", label: "Delaware" },
                    { value: "Florida", label: "Florida" },
                    { value: "Georgia", label: "Georgia" },
                    { value: "Hawaii", label: "Hawaii" },
                    { value: "Illinois", label: "Illinois" },
                    { value: "Idaho", label: "Idaho" },
                    { value: "Indiana", label: "Indiana" },
                    { value: "Iowa", label: "Iowa" },
                    { value: "Kansas", label: "Kansas" },
                    { value: "Kentucky", label: "Kentucky" },
                    { value: "Louisiana", label: "Louisiana" },
                    { value: "Maine", label: "Maine" },
                    { value: "Maryland", label: "Maryland" },
                    { value: "Massachusetts", label: "Massachusetts" },
                    { value: "Michigan", label: "Michigan" },
                    { value: "Minnesota", label: "Minnesota" },
                    { value: "Mississippi", label: "Mississippi" },
                    { value: "Missouri", label: "Missouri" },
                    { value: "Montana", label: "Montana" },
                    { value: "Nebraska", label: "Nebraska" },
                    { value: "Nevada", label: "Nevada" },
                    { value: "New Hampshire", label: "New Hampshire" },
                    { value: "New Jersey", label: "New Jersey" },
                    { value: "New Mexico", label: "New Mexico" },
                    { value: "New York", label: "New York" },
                    { value: "North Carolina", label: "North Carolina" },
                    { value: "North Dakota", label: "North Dakota" },
                    { value: "Ohio", label: "Ohio" },
                    { value: "Oklahoma", label: "Oklahoma" },
                    { value: "Oregon", label: "Oregon" },
                    { value: "Pennsylvania", label: "Pennsylvania" },
                    { value: "Rhode Island", label: "Rhode Island" },
                    { value: "South Carolina", label: "South Carolina" },
                    { value: "South Dakota", label: "South Dakota" },
                    { value: "Tennessee", label: "Tennessee" },
                    { value: "Texas", label: "Texas" },
                    { value: "Utah", label: "Utah" },
                    { value: "Vermont", label: "Vermont" },
                    { value: "Virginia", label: "Virginia" },
                    { value: "Washington", label: "Washington" },
                    { value: "West Virginia", label: "West Virginia" },
                    { value: "Wisconsin", label: "Wisconsin" },
                    { value: "Wyoming", label: "Wyoming" },
                  ]}
                  onChange={(value) => {
                    setState(value["value"]);
                  }}
                  name="state"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  name="zipcode"
                  required
                  fullWidth
                  value={zipcode}
                  label="Zip Code"
                  type="tel"
                  error={!zipcodeValidityCheck}
                />
                {!zipcodeValidityCheck ? (
                  <p className="validation-error">Enter valid zipcode</p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  onChange={handleChange}
                  name="country"
                  required
                  fullWidth
                  value="USA"
                  label="Country"
                  disabled
                />
              </Grid>
            </Grid>
            <DialogActions className="signup-dialog">
              <Button
                type="submit"
                className="signup-profile-save-btn"
                variant="contained"
                disabled={loader}
              >
                {loader ? <CircularProgress size={25} /> : "Save"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CustomerSignupPage;
