import React from "react";
import "./ResetPassword.css";
import { TextField } from "@material-ui/core";
import { useState } from "react";
import swal from 'sweetalert';
import { IP } from "../ip/IPConfig";

function ResetPassword(props) {
  let [newPass, setNewPass] = useState("");
  let [confirmPass, setConfirmPass] = useState("");
  let [checkBtn, setCheckBtn] = useState("");
  let error = "";

  const handleChange = (e) => {
    if (e.target.name == "New Password") {
      setNewPass(e.target.value);
    }
    if (e.target.name == "Confirm Password") {
      setConfirmPass(e.target.value);
    }
    if (e.target.name == "Show-Password") {
      setCheckBtn(checkBtn == "" ? "on" : "");
    }
  };

  if (confirmPass != "" && newPass != confirmPass) {
    error = <p className="error-msg">Passwords don't match*</p>;
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${IP}/user/resetpassword/${sessionStorage.getItem("resetToken")}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        new_password:confirmPass,
      }),
    })
      .then((res) => {
        const response = res.text();
        console.log("changed password response", response);
        swal("Done","Password Reset Successful","success")
      })
      .catch((err) => {
        console.log("error", err);
        swal("Failed to Reset","Try Again Later","error")
      });
  };

  return (
    <div>
      <div className="reset-password-main-div">
        <div className="reset-heading">
          <h1>Reset Password</h1>
        </div>
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            name="New Password"
            label="New Password*"
            className="reset-inputs"
            type={checkBtn == "" ? "password" : ""}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            name="Confirm Password"
            label="Confirm Password*"
            className="reset-inputs"
            type={checkBtn == "" ? "password" : ""}
            onChange={handleChange}
          />
          <div>{error}</div>
          <div className="show-password-div">
            <input
              type="checkbox"
              name="Show-Password"
              id="checkbox"
              checked={checkBtn}
              onChange={handleChange}
            />
            <label htmlFor="checkbox">Show Password</label>
          </div>
          <button
            type="submit"
            className="reset-button"
            name="check-btn"
          >
            Confirm
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
