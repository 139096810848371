import React, { Component } from "react";
import "./WaitingScreen.css";
import one from "../../../images/undraw_informed_decision_p2lh.svg";
import two from "../../../images/undraw_season_change_f99v.svg";
import three from "../../../images/undraw_Access_account_re_8spm.svg";
class WaitingScreen extends Component {
  render() {
    return (
      <div>
        <div className="waiting-outer-div">
          <h1 className="waiting-heading"> Welcome {sessionStorage.getItem("dealer_name")}!</h1>
          <div className="waiting-card-list">
            <div className="waiting-card-item" id="waiting-card-item-1">
              <img src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fundraw_informed_decision_p2lh.svg?alt=media&token=062c1406-87ca-4f34-abd7-e2f2f528e732" style={{ width: "100%" }} />
              <h3 className="waiting-card-title">
                Registration in process. Please contact us at
                contact@warrantyninja.com{" "}
              </h3>
            </div>
            <div className="waiting-card-item">
              <img src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fundraw_season_change_f99v.svg?alt=media&token=30556ee6-7e8a-4f09-8896-a511ee327a0a" style={{ width: "100%" }} />
              <h3 className="waiting-card-title">
                Checking authenticity , Kindly wait for the approval
              </h3>
            </div>
            <div className="waiting-card-item">
              <img src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2Fundraw_Access_account_re_8spm.svg?alt=media&token=564938ad-42a6-453c-8e60-a296bc1b753c" style={{ width: "100%" }} />
              <h3 className="waiting-card-title">
                Boom! Ready to bid on your car quotes
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WaitingScreen;
