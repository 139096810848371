//items to be showed in navbar for Main page/Landing page of the project

export const LandinPageNav = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Dealer",
      url: "/dealer/login",
    },
    
    {
      title: "Customer",
      url: "/customer/login",
    },
    // {
    //   title: sessionStorage.getItem("role"),
    //   url: `/${sessionStorage.getItem("role")}`
    // }
  ];
  