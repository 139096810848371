import React, { useEffect, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import "./CustomerQuotes.css";
import { IP } from "../../../ip/IPConfig";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../Pagination";
import * as _ from "lodash";
import image from "../../../images/brokenCar.webp";
import { Spinner1 } from "../../../Spinners";
import service1 from "../../../images/service1.webp";
import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

// import { useSelector } from "react-redux";
// import { customerLogin, customerLoginData } from "../../../../redux/actions";
// import { useDispatch } from "react-redux";

function CustomerQuotes(props) {
  let [carquotes, setcarquotes] = useState(null); // car quotes are stored in this array
  let [carquotes2, setcarquotes2] = useState([]); // car quotes for sorting are stored in this array

  const [showPerPage, setShowPerPage] = useState(8); // how many quotes to show per page
  const [pagination, setPagination] = useState({
    // pagination object to slice the array and shiw only 8 items on a page
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    // on page change this function is called
    setPagination({ start: start, end: end });
  };

  // fetching the car quotes from the server
  const getQuotes = () => {
    fetch(`${IP}/customer/quotes/${sessionStorage.getItem("customerId")}`, {
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`, // passing the token generated after login to get the quotes
      },
    })
      .then(async (res) => {
        const response = await res.json();
        setcarquotes(response); // storing the car quotes in a array
        sessionStorage.setItem("carquotes", JSON.stringify(response));
      })
      .catch((err) => {
        console.log("error in customer quote ", err);
      });
  };

  useEffect(() => {
    getQuotes(); // call to the car quotes function each time page is refreshed or rendered
    window.scrollTo({ top: 0 });
  }, []);

  // sort function to sort the *carquotes* array according to car make or date and then stores the sorted array in *carquotes2*
  const sortArray = (type) => {
    const types = {
      Date: "timestamp",
      Brand: "car_maker",
    };
    const sortProperty = types[type];
    let sorted = _.sortBy(carquotes, sortProperty); // lodash sort function is used to sort array it takes _.sortBy(array,property to sort by)
    if (types[type] == "timestamp") {
      sorted = sorted.reverse(); // date is sorted from oldest to newest by lodash sort and to make it newest to oldest reverse array function is used
    }
    setcarquotes2(sorted); // sorted array is stored in the
  };

  // ************************************Delete Quote Function*****************************************

  // handleCarDelete(quote id) takes quote id as argument and deletes the quote associated with quote id from carquotes and stores newly formed array into carquotes 2
  const handleCarDelete = (quoteId) => {
    let arr = []; // temporary array used to re render the quotes and remove when deleted dynamically

    carquotes.map((data) => {
      if (data.quote_id == quoteId) {
        console.log(data.quote_id);
      } else {
        arr.push(data);
      }
    });
    setcarquotes2(arr);

    // delete of the quote from the server by fetch 'PATCH' operation
    fetch(`${IP}/customer/quote/delete/${quoteId}`, {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        console.log(response);
      })
      .catch((err) => {
        console.log("Error in delete", err);
      });
  };

  // *************************************************************************************************

  //*************************************Material Ui Dialog Essentials********************************** */

  const [open, setOpen] = useState(false);
  const [quoteId, setQuoteID] = useState("");

  const handleClickOpen = (quote_Id) => {
    setOpen(true);
    setQuoteID(quote_Id);
  };

  const handleClose = () => {
    setOpen(false);
    handleCarDelete(quoteId);
    setQuoteID("");
  };

  // *****************************************************************************************************

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this quote ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className="customer-head-title">
        <div className="quotes-heading">
          <h5>Welcome,</h5>

          <h1>{sessionStorage.getItem("user_name")}!</h1>
          <h5>Here is your bid list</h5>
        </div>
        <div className="customer-head-image">
          <img src={service1} alt="service" />
        </div>
      </div>
      {carquotes != null ? ( // conditional rendering :- if carquotes is null show loading icon
        <div>
          {carquotes.length > 0 ? ( // conditional rendering :- if car quotes is empty array it shows
            <div className="quotes-list">
              <div className="sorting-div">
                <h5>Sort By : </h5>
                <select
                  onChange={(e) => sortArray(e.target.value)} // here we call sort function and pass the target value date or brand
                  className="sort-select"
                >
                  <option value="select">Select value</option>
                  <option value="Date">Date</option>
                  <option value="Brand">Brand</option>
                </select>
              </div>
              <div className="customer-quotes-card-main-container">
                {(carquotes2.length == 0 ? carquotes : carquotes2) // for dynamically changing the quotes either carquotes is diplayed or carquotes2 based on the condition
                  .slice(pagination.start, pagination.end) // array is sliced to show only 8 quotes per page
                  .map((data, index) => {
                    // then array is mapped to display individual quotes
                    return data.isactive ? ( // only active quotes are displayed based on the flage isactive
                      <div className="customer-quotes-card-container">
                        <div className="customer-quote-card-stack">
                          <div className="customer-quote-card-stack-text">
                            <div>
                              {/* <h5>Car Maker</h5> */}
                              <small>{data.car_maker}</small>

                              {/* <h5>Car Model</h5> */}
                              <h5>{data.car_model}</h5>
                            </div>
                            <div className="customer-quote-card-stack-icon-wrapper">
                              <h4
                                className="fa fa-trash"
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete Quote"
                                // onClick={() => handleCarDelete(data.quote_id)}
                                onClick={() => handleClickOpen(data.quote_id)}
                              ></h4>
                              {/* </OverlayTrigger> */}
                            </div>
                          </div>
                        </div>
                        <div className="customer-quote-card-stack-down">
                          <div style={{ textAlign: "right", margin: "3% 0%" }}>
                            <small style={{ marginTop: "3%" }}>
                              {moment(data.timestamp).fromNow()}{" "}
                              {/*time stamp is converted to normalized format*/}
                            </small>
                          </div>
                          <div
                            className="customer-icon-detail"
                            style={{
                              display: "flex",
                              margin: "3% 5%",
                              width: "90%",
                              marginLeft: "5%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "15px",
                                marginTop: "3%",
                              }}
                            >
                              Quote Id : {data.quote_id}
                            </div>
                          </div>
                          <div
                            className="customer-icon-detail"
                            style={{
                              display: "flex",
                              width: "90%",
                              margin: "3% 5%",
                              marginLeft: "5%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "15px",
                                marginTop: "3%",
                              }}
                            >
                              Car Model
                            </div>
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "5%",
                              }}
                            >
                              <h6>{data.car_model}</h6>
                            </div>
                          </div>
                          <div
                            className="customer-icon-detail"
                            style={{
                              display: "flex",
                              width: "90%",
                              margin: "3% 5%",
                              marginLeft: "5%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "15px",
                                marginTop: "3%",
                              }}
                            >
                              Mileage
                            </div>
                            <div
                              style={{
                                marginTop: "3%",
                                marginLeft: "5%",
                              }}
                            >
                              <h6>{data.car_mileage}</h6>
                            </div>
                          </div>
                          <div className="buttons-div-customer">
                            <Link
                              to={`/customer/quote/bid/${data["quote_id"]}`}
                            >
                              <button>
                                <p>View Bids</p>
                                <Badge
                                  color="error"
                                  badgeContent={
                                    data.bid_done == 0 ? "0" : data.bid_done
                                  }
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                ></Badge>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}
              </div>
            </div>
          ) : (
            <div className="no-data-illustration">
              <div className="no-data-img-div">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/warrantyninja-4a6e2.appspot.com/o/website%2FbrokenCar.webp?alt=media&token=ef20e24e-476b-4dc7-bcf3-cc2316f120f3"
                  alt=""
                />
              </div>
              <div className="no-data-text-btn-div">
                <h3>You are all caught up !! Add a Car here</h3>
                <Button variant="contained">
                  <Link to="/customer/quote" style={{ textDecoration: "none" }}>
                    {" "}
                    Add car
                  </Link>
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Spinner1 />
        </div>
      )}
      {carquotes && carquotes.length > 0 ? ( // Add car button renered only when quotes are available
        <div>
          <center>
            <Button variant="contained">
              <Link to="/customer/quote" style={{ textDecoration: "none" }}>
                {" "}
                Add car
              </Link>
            </Button>
          </center>
          <Pagination
            showPerPage={showPerPage}
            onPaginationChange={onPaginationChange}
            total={carquotes.length}
          />
        </div>
      ) : null}
    </>
  );
}

export default CustomerQuotes;
